import {FieldIfEvaluation} from './FieldIfEvaluator';
import {Validation} from '../../declarations/meta/Validation';


/**
 * Defines all available validation-types
 */
export enum ValidationType {
  REQUIRED = 'required',
  PATTERN = 'pattern',
  MIN = 'min',
  MAX = 'max',
  COMPARE_FIELD = 'compare',
  USERNAME = 'username'
}

/**
 * All available params in a ValidationMessageFactory
 */
export interface ValidationMessageFactoryProps {
  /**
   * The value that was used in validation
   */
  value: any;
  /**
   * The pattern the value was matched against.
   * Present when type is PATTERN or USERNAME
   */
  pattern?: string;
  /**
   * The lowest value or value-length allowed.
   * Present when type is MIN
   */
  min?: number;
  /**
   * The highest value or value-length allowed.
   * Present when type is MAX
   */
  max?: number;
  /**
   * When "min" or "max" is present,
   * this value will be true if the min/max signifies the LENGTH of the value,
   * instead of the value itself.
   * Present when type is MIN or MAX
   */
  isLength?: boolean;
  // TODO: Add values for COMPARE_FIELD
}

/**
 * A function that builds a validation-error-message based on the type and provided params.
 */
export type ValidationMessageFactory = (validationType: ValidationType, params?: ValidationMessageFactoryProps) => string;

/**
 * An object describing all validation-errors for a field
 */
export type ValidationErrors = {[type in ValidationType]: string};

let hasDisplayedFactoryWarning = false;

/**
 * Definition of default error-messages, in case it is not provided.
 *
 * @param {ValidationType} validationType
 * @param {ValidationMessageFactoryProps} params
 * @return {string}
 */
const defaultMessageFactory: ValidationMessageFactory = (validationType, params?: ValidationMessageFactoryProps) => {
  if (!hasDisplayedFactoryWarning) {
    console.warn('Using default validation-message-factory. ' +
      'Consider providing your own factory to enhance the user ' +
      'experience by providing localized validation-messages');
    hasDisplayedFactoryWarning = true;
  }
  switch (validationType) {
    case ValidationType.REQUIRED: return 'This field is required';
    case ValidationType.PATTERN: return 'Value does not match the current pattern';
    case ValidationType.MIN: return `The value must be ${params?.isLength ? 'longer' : 'greater'} than ${params?.min ?? '-'}`;
    case ValidationType.MAX: return `The value must be ${params?.isLength ? 'shorter' : 'less'} than ${params?.max ?? '-'}`;
    case ValidationType.COMPARE_FIELD: return '';
    case ValidationType.USERNAME: return 'The value is not a valid username';
  }
}

/**
 * Validates the value against a provided PATTERN
 * @param {ValidationErrors} errors
 * @param {Validation} validation
 * @param value
 * @param {ValidationMessageFactory} vmf
 */
const validatePattern = (errors: ValidationErrors, validation: Validation, value: any, vmf: ValidationMessageFactory): void => {
  const regex = validation.reg_exp;
  if (!!regex && !new RegExp(regex).test(value)) {
    errors[ValidationType.PATTERN] = vmf(ValidationType.PATTERN, {value, pattern: regex});
  }
}

/**
 * Validates that a value or the length of a value is less than the specified min-value
 * @param {ValidationErrors} errors
 * @param {Validation} validation
 * @param value
 * @param {ValidationMessageFactory} vmf
 */
const validateMin = (errors: ValidationErrors, validation: Validation, value: any, vmf: ValidationMessageFactory): void => {
  const isLength = Number.isNaN(Number(value));
  const compareValue: number = isLength ? String(value).length : value as number;
  let min = validation?.min_length;

  if (isLength && !!min && min < 0) {
    console.warn('The length of a string cannot be negative... Overriding min to \'0\'.' +
      'This has no practical side-effects, other than creating a conceptually correct validation, ' +
      'but it signifies an incorrect definition of a validation-meta');
    min = 0;
  }

  if (min !== undefined && min !== null && !Number.isNaN(min) && compareValue < min) {
    errors[ValidationType.MIN] = vmf(ValidationType.MIN, {value, min, isLength});
  }
}

/**
 * Validates that a value or the length of a value is larger than the specified max-value
 * @param {ValidationErrors} errors
 * @param {Validation} validation
 * @param value
 * @param {ValidationMessageFactory} vmf
 */
const validateMax = (errors: ValidationErrors, validation: Validation, value: any, vmf: ValidationMessageFactory): void => {
  const isLength = Number.isNaN(Number(value));
  const compareValue: number = isLength ? String(value).length : value as number;
  const max = validation?.max_length;

  if (max !== undefined && max !== null && !Number.isNaN(max) && compareValue > max) {
    errors[ValidationType.MAX] = vmf(ValidationType.MAX, {value, max, isLength});
  }
}

/**
 * Compares and validates the value to another value in the model.
 * @param {ValidationErrors} errors
 * @param {Validation} validation
 * @param value
 * @param {ValidationMessageFactory} vmf
 */
// @ts-ignore
const validateCompareField = (errors: ValidationErrors, validation: Validation, value: any, vmf: ValidationMessageFactory): void => {
  // TODO: Implement this, and remove ts-ignore
}

/**
 * Validates that the value is a valid username
 * @param {ValidationErrors} errors
 * @param {Validation} validation
 * @param value
 * @param {ValidationMessageFactory} vmf
 */
const validateUsername = (errors: ValidationErrors, validation: Validation, value: any, vmf: ValidationMessageFactory): void => {
  if (!validation?.username) {
    return;
  }
  // FIXME: Add correct pattern
  // TODO: Check if username exists
  const pattern = '.*';
  if (!new RegExp(pattern).test(value)) {
    errors[ValidationType.USERNAME] = vmf(ValidationType.USERNAME, {value, pattern});
  }
}

/**
 * Validates a field based on the provided validation-meta
 * @param {FieldIfEvaluation} fieldIfEvaluation
 * @param value
 * @param {ValidationMessageFactory} messageFactory
 * @return {ValidationErrors | null}
 */
export const validatePrimusField = (fieldIfEvaluation: FieldIfEvaluation, value: any, messageFactory?: ValidationMessageFactory): ValidationErrors | null => {
  const errors = {} as ValidationErrors;
  const msgFactory = messageFactory ?? defaultMessageFactory;

  if (fieldIfEvaluation.require && !value) {
    // TODO: Translation and customization of error-message
    errors[ValidationType.REQUIRED] = msgFactory(ValidationType.REQUIRED);
  }

  const validation = fieldIfEvaluation.field?.validation;

  if (!!validation && !!value) {
    validatePattern(errors, validation, value, msgFactory);
    validateMin(errors, validation, value, msgFactory);
    validateMax(errors, validation, value, msgFactory);
    validateCompareField(errors, validation, value, msgFactory);
    validateUsername(errors, validation, value, msgFactory);
  }

  return !!Object.keys(errors).length ? errors : null;
};