export abstract class BaseBuilder<T> {

  protected readonly product: T;
  private readonly onBuild?: (buildResult: T) => void

  protected constructor(product: T, onBuild?: (buildResult: T) => void) {
    this.product = product;
    this.onBuild = onBuild;
  }

  /**
   * Calls the "onBuild" callback, and returns the product being build in it's current state
   * @return {T}
   */
  public build(): T {
    if (this.onBuild) {
      this.onBuild(this.product);
    }
    return this.product;
  }
}