import React, {FC, ReactNode} from 'react';
import {Drawer, DrawerProps, styled} from '@material-ui/core';

export interface AppLayoutProps {
  headerContent?: ReactNode;
  menuContent?: ReactNode;
  footerContent?: ReactNode;
  menuPosition?: DrawerProps['anchor'];
  menuOpen?: boolean;
  onMenuClose?: () => void;
}

const Container = styled('div')({
  display: 'flex',
  position: 'absolute',
  width: '100vw',
  height: '100vh',
  flexFlow: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  margin: 0,
  padding: 0,
  overflow: 'hidden'
});

const ContentWrapper = styled('main')(({theme}) => ({
  overflow: 'auto',
  width: '100vw',
  height: `100%`,
  padding: theme.spacing(2, 1),
  boxSizing: 'border-box'
}));

const HeaderContainer = styled('div')({
  width: '100vw'
});

const FooterContainer = styled('div')({
  width: '100vw'
});

export const AppLayout: FC<AppLayoutProps> = ({
                                                headerContent,
                                                children,
                                                footerContent,
                                                menuContent,
                                                menuPosition = 'left',
                                                menuOpen = false,
                                                onMenuClose
}) => {

  return (
    <Container>

      <HeaderContainer>
        { headerContent }
      </HeaderContainer>

      <Drawer anchor={menuPosition} open={menuOpen} onClose={() => onMenuClose && onMenuClose()}>
        { menuContent }
      </Drawer>

      <ContentWrapper>
        { children }
      </ContentWrapper>

      <FooterContainer>
        { footerContent }
      </FooterContainer>

    </Container>
  );
};

export default AppLayout;