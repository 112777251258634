import {MetaField} from "../../declarations/meta/MetaField";
import {SearchParams} from '../../declarations/SearchParams';

export abstract class ModelHelpers {

  /**
   * Takes a meta-field and uses it's reference-data to generate the SearchParams
   * to use, to fetch the available values.
   * @param {MetaField} field the field to fetch the params for
   * @param query A potential querystring to further filter the result
   * @param includeIds A potential list containing the IDs to always include in the result.
   * Useful if e.g. the user can select multiple elements,
   * and the selected elements should always be displayed in the list of selectable elements.
   * @return {SearchParams}
   */
  public static getSearchParamsForReference(field: MetaField, query: string = '', includeIds: Array<string> = []): SearchParams {
    if (!field?.reference) {
      return {query} as SearchParams;
    }
    const ref = field.reference;
    const refIdProp = ref.ref_prop || 'artifact_id';
    const refNameProp = ref.label_prop || 'artifact_name';

    let q = `${refNameProp}:*${query}*`;
    if (!!includeIds?.length) {
      q = `${refIdProp}:(${includeIds.join(',')}) OR (${q})`;
    }
    const params = {
      query: q,
      fl: [refIdProp, refNameProp],
      sort: `${refNameProp} asc`
    } as SearchParams;

    params.fq = [
      '(-marked:true AND (',
      ref.object_type ? `object_type:"${ref.object_type}"` : '',
      ref.object_type && ref.meta_type ? ' AND ' : '',
      ref.meta_type ? `meta_type:"${ref.meta_type}"` : '',
      '))'
    ].join('');

    if (ref.ref_filter) {
      const filters = Array.isArray(ref.ref_filter) ? ref.ref_filter : [ref.ref_filter];
      const values = filters.map(filter => `${filter.filter_field}:(${filter.values?.filter(val => typeof val === 'string').join(',')})`);
      params.fq += ` AND (${values.join(' AND ')})`;
    }

    if (ref.sort) {
      params.sort = ref.sort;
    }

    return params;
  }

}