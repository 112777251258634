
export class DebounceTimer {

  private readonly debounceTime: number
  private lastTime: number;
  private timeoutId: number | null;

  constructor(debounceTime: number) {
    this.debounceTime = debounceTime;
    this.lastTime = Date.now();
    this.timeoutId = null;
    this.startTimer = this.startTimer.bind(this);
    this.stopTimer = this.stopTimer.bind(this);
    this.debounce = this.debounce.bind(this);
  }

  private startTimer(handler: () => void | Promise<void>): void {
    if (this.timeoutId === null && handler) {
      const wrapper = async () => {
        await handler();
        this.stopTimer();
      };
      this.timeoutId = setTimeout(wrapper, this.debounceTime) as any as number;
    }
  }

  public stopTimer(): void {
    if (this.timeoutId !== null) {
      clearTimeout(this.timeoutId);
      this.timeoutId = null;
    }
  }

  public debounce(handler: () => void | Promise<void>): void {
    const currentTime = Date.now();
    const elapsedTime = currentTime - this.lastTime;

    if (elapsedTime < this.debounceTime) {
      this.stopTimer();
    }
    this.startTimer(handler);

    this.lastTime = currentTime;
  }

}