import {SubModelBuilder} from './SubModelBuilder';
import {KulturnavParamsBuilder} from './KulturnavParamsBuilder';
import {AgentSubType} from '../../../declarations/meta/AgentSubType';

export class AgentSubTypeBuilder extends SubModelBuilder<KulturnavParamsBuilder, AgentSubType> {

  constructor(parentBuilder: KulturnavParamsBuilder, paramType: string, onBuild: (buildResult: AgentSubType) => void) {
    super(parentBuilder, {param_type: paramType, options: []}, onBuild);
  }

  public withOption(label: string, selected: boolean, value: string): AgentSubTypeBuilder {
    this.product.options.push({label, selected, value});
    return this;
  }

}