import React, {FC, useCallback, useEffect, useState} from 'react';
import {usePrimusApi} from '../../hooks/PrimusApiProvider';
import PrimusEkulturLoginContainer from './PrimusEkulturLoginContainer';
import {Button, MenuItem, TextField} from '@material-ui/core';
import {PrimusServer} from '../../declarations/PrimusServer';
import PrimusEkulturLoading from './PrimusEkulturLoading';
import {useLoginLabels} from './PrimusEkulturLabelProvider';
import {goToInitialUrl} from './PrimusEkulturLoginGlobals';


export interface PrimusEkulturSelectPrimusInstanceProps {
  onLogin?: () => void | Promise<void>;
}

export const PrimusEkulturSelectPrimusInstance: FC<PrimusEkulturSelectPrimusInstanceProps> = ({children, onLogin}) => {
  const api = usePrimusApi()!;
  const labels = useLoginLabels();

  const [loading, setLoading] = useState<boolean>(false);
  const [loginFailed, setLoginFailed] = useState<boolean>(false);
  const [selectedServer, setSelectedServer] = useState<string>('');
  const [availableServers, setAvailableServers] = useState<Array<PrimusServer>>([]);

  const handleSelectServer = useCallback(async (serverId: string | number) => {
    try {
      setLoading(true);
      const server = availableServers.find(srv => String(srv.id) === String(serverId));
      await api.loginPrimus(server?.serverUrl!);
      if (!!onLogin) {
        await onLogin();
      }
      goToInitialUrl();
    } catch (e) {
      console.error(e);
      setLoginFailed(true);
    } finally {
      setLoading(false);
    }
  }, [availableServers, api, onLogin]);

  useEffect(() => {
    if (!!availableServers?.length && !String(selectedServer)) {
      try {
        const serverId = availableServers[0]?.id?.toString();
        setSelectedServer(serverId);

        if (availableServers.length === 1) {
          handleSelectServer(serverId).then();
        }

      } catch (e) {
        console.error(e);
        setLoginFailed(true);
      }
    }
  }, [availableServers, handleSelectServer]);

  useEffect(() => {
    if (!api.getServer() && !availableServers?.length && !loginFailed) {
      setLoading(true);
      api.getLoggedInUserInfo()
        .then(res => res?.uniqueId)
        .then(id => api.getAvailablePrimusInstances(id))
        .then(servers => {
          if (window.location.host.startsWith('localhost')) {
            servers.unshift({
              id: -1,
              name: 'Localhost:5000',
              serverUrl: 'http://localhost:5000'
            } as PrimusServer);
          }
          if (!servers.length) {
            setLoginFailed(true);
            console.error('No servers available for user');
          }
          return servers;
        })
        .then(setAvailableServers)
        .catch(err => {
          console.error(err);
          setLoginFailed(true);
        })
        .finally(() => setLoading(false));
    }
  }, [loginFailed, api, availableServers]);

  if (!!api.getServer()) {
    return <>{children}</>;

  } else if (loading || loginFailed) {
    return (
      <PrimusEkulturLoginContainer>
        <PrimusEkulturLoading loading={loading}
                              text={loginFailed ? labels.loginFailed : labels.loggingIn}
                              textColor={loginFailed ? 'error' : 'initial'}/>
      </PrimusEkulturLoginContainer>
    );

  }

  return (
    <PrimusEkulturLoginContainer>
      <>
        <TextField label={labels.selectServerInputLabel}
                   name={labels.selectServerInputLabel}
                   disabled={availableServers?.length <= 1}
                   value={selectedServer}
                   onChange={event => setSelectedServer(event.target.value as string)}
                   fullWidth
                   select>
          {
            availableServers.map(server => (
              <MenuItem key={server.id}
                        value={server.id}>
                {server.name}
              </MenuItem>
            ))
          }
        </TextField>

        <Button type="button"
                variant="contained"
                color="primary"
                name={labels.selectServerButtonLabel}
                onClick={() => handleSelectServer(selectedServer!)}
                disabled={!selectedServer}>
          {labels.selectServerButtonLabel}
        </Button>
      </>
    </PrimusEkulturLoginContainer>
  );
};

export default PrimusEkulturSelectPrimusInstance;