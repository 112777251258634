
export abstract class LocationHelpers {

  public static getCurrentSearchParams(): {[name: string]: string} {
    return location.search.slice(1)
      .split('&')
      .filter(p => !!p)
      .map(param => param.split('='))
      .reduce((acc, val) => ({...acc, [val[0]]: decodeURI(val[1])}), {});
  }

  public static getCurrentSearchParam(paramName: string): string | null {
    if (!paramName) {
      return null;
    }
    return LocationHelpers.getCurrentSearchParams()[paramName] || null;
  }

  public static replaceParam(paramName: string, value: string): string {
    const [url] = location.href.split('?');
    const search = LocationHelpers.getCurrentSearchParams();
    search[paramName] = value;
    const searchString = Object.keys(search).map(key => `${key}=${encodeURI(search[key])}`).join('&');
    return `${url}?${searchString}`;
  }

}