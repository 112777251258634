/*
 * Everything in this file is for internal use only, and should not be exported in index
 */
import {FieldType} from './JsonSchemaFieldType';
import {InputType} from '../../declarations/InputType';

type InputTypeToFieldTypeMap = { [name in InputType]: FieldType };

export const DEFAULT_FIELD_TYPE: FieldType = 'string';

export const INPUT_TYPE_TO_FIELD_TYPE_MAP: InputTypeToFieldTypeMap = {
  [InputType.CHECK_ARRAY]: 'array',
  [InputType.INLINE_ARRAY]: 'array',
  [InputType.REF_ARRAY]: 'array',
  [InputType.REF_DYNAMIC_ARRAY]: 'array',
  [InputType.OBJECT_REFERENCE]: 'array',
  [InputType.SEARCH_SELECTOR_MULTIPLE]: 'array',

  [InputType.CHECKBOX]: 'boolean',

  [InputType.NUMBER]: 'number',

  [InputType.INLINE]: 'object',

  [InputType.PRECISION_DATE]: 'object',

  [InputType.SEARCH_SELECTOR]: 'string',

  [InputType.COMPARE_VALUE]: 'string',
  [InputType.IMAGE]: 'string',
  [InputType.TEXT_AREA]: 'string',
  [InputType.INPUT]: 'string',
  [InputType.MAP_ID]: 'string',
  [InputType.IDENTIFIER]: 'string',
  [InputType.ACTION_BUTTON]: 'string'
};

export const getFieldTypeFromInputType = (inputType?: InputType): FieldType => {
  if (!inputType || !(inputType in INPUT_TYPE_TO_FIELD_TYPE_MAP)) {
    return DEFAULT_FIELD_TYPE;
  }
  return INPUT_TYPE_TO_FIELD_TYPE_MAP[inputType];
}

export default getFieldTypeFromInputType;