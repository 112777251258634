import React, {FC, useEffect, useMemo, useState} from 'react';
import {FieldProps} from '@rjsf/core';
import {Autocomplete} from '@material-ui/lab';
import {Checkbox, CircularProgress, TextField} from '@material-ui/core';
import {CheckBox, CheckBoxOutlineBlank, Search} from '@material-ui/icons';
import {ModelHelpers} from '../../../asset/js/ModelHelpers';
import {KeyValue} from '../../../declarations/KeyValue';
import {DebounceTimer} from '../../../asset/js/DebounceTimer';
import {SearchResult} from '../../../declarations/SearchResult';
import {PrimusApi} from '../../../services/PrimusApi';
import {MetaField} from '../../../declarations/meta/MetaField';

export interface SearchSelectorProps<T> extends FieldProps<T> {
  multiple: boolean;
}

type Option = KeyValue<string>;
type Options = Array<Option>;

const debounceTimer = new DebounceTimer(350);

class QueryCache {

  private readonly api: PrimusApi;
  private readonly field: MetaField;
  private cache: {[query: string]: SearchResult};

  constructor(api: PrimusApi, field: MetaField) {
    this.clearCache();
    this.api = api;
    this.field = field;
  }

  public async search(query: string = '*', selectedIds: Array<string> = []): Promise<Options> {
    if (!this.cache.hasOwnProperty(query)) {
      this.cache[query] = await this.api.search(ModelHelpers.getSearchParamsForReference(this.field, query, selectedIds));
    }
    return this.mapKV(this.cache[query])
  }

  public clearCache(): void {
    this.cache = {};
  }

  private mapKV(res: SearchResult): Options {
    return (res?.artifacts || []).map(artifact => ({
      key: artifact.artifact_id,
      value: artifact.artifact_name
    } as Option));
  }
}





export const SearchSelector: FC<SearchSelectorProps<string | Array<string>>> = ({
                                                                           id,
                                                                           name,
                                                                           schema: {title},
                                                                           formData,
                                                                           disabled,
                                                                           required,
                                                                           readonly,
                                                                           onChange,
                                                                           rawErrors,
                                                                           formContext,
                                                                           multiple= false
                                                                         }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const selectedIds = useMemo<Array<string>>(() => {
    if(formData) {
      return Array.isArray(formData) ? formData : [formData];
    }
    return [];
  } , [formData]);

  const searchCache = useMemo<QueryCache>(() => {
    if (searchCache) {
      searchCache.clearCache();
    }
    return new QueryCache(formContext.api, formContext.meta[name]);
  }, [name, formContext]);

  const [value, setValue] = useState<Option | Options | null>(multiple ? [] : null);
  const [searchValue, setSearchValue] = useState<string>('');

  const [currentOptions, setCurrentOptions] = useState<Options>([]);

  const loadOptions = async (query?: string) => {
    setLoading(true);
    try {
      const options = await searchCache.search(query, selectedIds);
      setCurrentOptions(options);
    } catch (e) {
      console.error(e);
      setCurrentOptions([]);
    } finally {
      setLoading(false);
    }
  }

  const handleSearchValueChanged = (_: any, value: string = ''): void => {
    setSearchValue(value);
    if (!value || value.length >= 3) {
      debounceTimer.debounce(() => loadOptions(value));
    }
  };

  const handleCurrentSelectionChanged = (value: Option | Options) => {
    setValue(value);
    if (multiple) {
      onChange((value as Options).map(opt => opt.key));
    } else {
      onChange((value as Option)?.key || null);
    }
  };

  useEffect(() => {
    loadOptions().then();
    return () => {
      debounceTimer.stopTimer();
      searchCache.clearCache();
    }
  }, []);

  return (
    <Autocomplete id={id}
                  multiple={multiple}
                  limitTags={1}
                  disableCloseOnSelect={multiple}
                  fullWidth
                  openOnFocus
                  open={open}
                  onOpen={() => setOpen(true)}
                  onClose={() => setOpen(false)}
                  value={value}
                  onChange={(_event, value: Options) => handleCurrentSelectionChanged(value)}
                  inputValue={searchValue}
                  onInputChange={handleSearchValueChanged}
                  options={currentOptions}
                  loading={loading}
                  disabled={disabled}
                  aria-readonly={readonly}
                  clearOnBlur={false}
                  getOptionLabel={opt => opt?.value || ''}
                  getOptionSelected={(currentOption: Option, selectedOption: Option) => currentOption?.key === selectedOption?.key}
                  renderOption={(option: Option, { selected }) => (
                    <span>
                      {
                        multiple &&
                        <Checkbox
                          icon={<CheckBoxOutlineBlank/>}
                          checkedIcon={<CheckBox/>}
                          style={{marginRight: 8}}
                          checked={selected}
                        />
                      }
                      {option.value || ''}
                    </span>
                  )}
                  renderInput={params => (
                    <TextField
                      {...params}
                      disabled={disabled}
                      aria-readonly={readonly}
                      error={!!rawErrors?.length}
                      label={title + (required ? '*' : '')}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <>
                            <Search color="disabled"/>
                            {params.InputProps.startAdornment}
                          </>
                        ),
                        endAdornment: (
                          <>
                            {loading && <CircularProgress color="inherit" size={20}/>}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}/>
                  )}/>
  );
};

export const SearchSelectorMultiple: FC<Omit<SearchSelectorProps<Array<string>>, 'multiple'>> = (props: SearchSelectorProps<Array<string>>) => (
 <SearchSelector {...props} multiple={true} />
);

export default SearchSelector;