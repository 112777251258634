import React, {createContext, FC, useContext, useEffect, useState} from 'react';
import {DEFAULT_LABELS, PrimusEkulturLoginLabels} from './PrimusEkulturLoginGlobals';

export interface PrimusEkulturLabelProviderProps {
  labels?: Partial<PrimusEkulturLoginLabels>;
}

const LabelContext = createContext<PrimusEkulturLoginLabels>(DEFAULT_LABELS);

export const useLoginLabels = () => useContext(LabelContext) || DEFAULT_LABELS;

export const PrimusEkulturLabelProvider: FC<PrimusEkulturLabelProviderProps> = ({children, labels}) => {
  const [currentLabels, setCurrentLabels] = useState<PrimusEkulturLoginLabels>(DEFAULT_LABELS);

  useEffect(() => {
    setCurrentLabels({
      ...DEFAULT_LABELS,
      ...labels
    });
  }, [labels]);

  return (
    <LabelContext.Provider value={currentLabels}>
      {children}
    </LabelContext.Provider>
  );
};

export default PrimusEkulturLabelProvider;