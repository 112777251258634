import {SubModelBuilder} from './SubModelBuilder';
import {MetaFieldBuilder} from './MetaFieldBuilder';
import {ActionListItem} from '../../../declarations/meta/ActionListItem';
import {Action} from '../../../declarations/meta/Action';

export class ActionBuilder extends SubModelBuilder<MetaFieldBuilder, Action> {

  constructor(parentBuilder: MetaFieldBuilder, trigger: Action['trigger'], onBuild: (buildResult: Action) => void) {
    super(parentBuilder, {action_list: [], trigger}, onBuild);
  }

  public withTrigger(trigger: Action['trigger']): ActionBuilder {
    this.product.trigger = trigger;
    return this;
  }

  public addItem(actionItem: ActionListItem): ActionBuilder {
    this.product.action_list.push(actionItem);
    return this;
  }

}