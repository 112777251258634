import React, {FC} from 'react';
import {CircularProgress, CircularProgressProps, Typography, TypographyProps} from '@material-ui/core';


export interface PrimusAppLoginLoadingProps {
  loading?: boolean;
  text?: string;
  textColor?: TypographyProps['color'];
  spinnerColor?: CircularProgressProps['color'];
}

export const PrimusEkulturLoading: FC<PrimusAppLoginLoadingProps> = ({
                                                                       children,
                                                                       loading = true,
                                                                       text = '',
                                                                       textColor = 'initial',
                                                                       spinnerColor = 'primary'
}) => (
  <>
    <Typography variant="body1" component="span" color={textColor}>
      {text}
    </Typography>
    {loading ? <CircularProgress variant="indeterminate" color={spinnerColor}/> : <span/>}
    {children ? children : <span/>}
  </>
);

export default PrimusEkulturLoading;