import {MetaField} from '../../declarations/meta/MetaField';
import {Model} from '../../declarations/Model';
import {Meta} from '../../declarations/Meta';
import {FieldIfCondition} from '../../declarations/meta/FieldIfCondition';
import {FieldIf} from '../../declarations/meta/FieldIf';



const evaluateIfs = (model: Model, field: MetaField, type: FieldIf['if_type'], defaultValue: boolean = true) => {
  const ifs = (field?.field_ifs || [] as Array<FieldIf>).filter(fif => fif.if_type === type);
  if (!ifs.length) {
    return defaultValue;
  }
  return ifs.some(fif => evaluateConditions(model, field, fif.operator, fif.conditions));
}

const evaluateConditions = (model: Model, field: MetaField, operator: FieldIf['operator'] = 'and', conditions: FieldIf['conditions'] = []) => {
  let prev: boolean = operator === 'and';

  for (const condition of conditions) {
    const current = getConditionValue(model, field, condition);
    if ((operator === 'or' && (prev || current)) || (operator === 'and' && prev && current)) {
      return true;
    }
    prev = current;
  }

  return false;
}

const getConditionValue = (model: Model, field: MetaField, condition: FieldIfCondition) => {
  if (!model || !field || !condition) return false;

  let fieldValue: any = null;
  let compareValue: any = condition.value || null;

  switch (condition.cond_type) {
    case 'compare_field_value':
      if (!condition.field) {
        console.warn('missing field');
        return false;
      }
      fieldValue = model[condition.field];
      break;
    case 'editing':
      // This component is only used in edit-mode
      return true;
    case 'edition':
      // TODO: Handle 'edition'
      // Check if the current edition is SMALL, MEDIUM or LARGE
      // return <currentEdition> <condition.comparator> <condition.value>
      console.warn('[FieldIfWrapper] cond_type: \'edition\' not implemented');
      return true;
    case 'not_editing':
      // This component is only used in edit-mode.
      return false;
    case 'field_value_in_values':
      if (!condition.values || !condition.field) {
        console.warn('[FieldIfWrapper] FieldIfCondition is missing \'values\' and/or \'field\'');
        return false;
      }
      return condition.values.includes(model[condition.field]);
    case 'compare_with_other_field':
      if (!condition.compare_field) {
        console.warn('[FieldIfWrapper] FieldIfCondition is missing \'compare_field\'');
        return false;
      }
      compareValue = model[condition.compare_field];
      break;
    case 'object_has_no_changes':
      // TODO: Handle 'object_has_no_changes'
      break;
  }

  if (fieldValue === null && compareValue === null) {
    return false;
  }

  if (condition.comparator === '!=') {
    return fieldValue !== compareValue;
  } else {
    return fieldValue === compareValue
  }
}




const getCanDisplay = (model: Model, field: MetaField) => {
  if (field?.display?.show !== 'yes') {
    // TODO: Handle `field.display.show === 'invisible'`
    // TODO: Handle `field.display.show === 'hid'`
    // TODO: Handle `field.display.show === 'header'`
    return false;
  }
  return evaluateIfs(model, field, 'show');
};

const getIsEditable = (model: Model, field: MetaField) => {
  if (!field || !field.edit || field.edit === 'no' || (field.edit === 'edit_once' && !!model.artifact_id)){
    if (field.edit === 'edit_owner') {
      // TODO: Handle `meta.edit == 'edit_owner'`
      console.warn('EDIT_OWNER NOT IMPLEMTENTED', field)
    }
    return false;
  }
  return evaluateIfs(model, field, 'edit');
};

const getIsDisabled = (model: Model, field: MetaField) => {
  return evaluateIfs(model, field, 'disable', false);
};




export interface FieldIfEvaluation {
  field: MetaField;
  display: boolean;
  edit: boolean;
  disable: boolean;
  require: boolean;
}





export const evaluatePrimusModelIfs = (model: Model, meta: Meta): Array<FieldIfEvaluation> => {
  return Object.keys(meta).map(k => meta[k] as MetaField).map(field => ({
    field,
    display: getCanDisplay(model, field),
    edit: getIsEditable(model, field),
    disable: getIsDisabled(model, field),
    require: field.required === 'required'
  } as FieldIfEvaluation))
}