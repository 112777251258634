/**
 * This abstract class provides helper-functions that manipulate objects
 */
export abstract class ObjectHelpers {
  /**
   * Copies an object or array of objects recursively.
   * @param {T} item The item or array of items to copy
   * @return {T} The copied item
   */
  public static deepCopy<T>(item?: T): T | null | undefined {
    if (item === null || item === undefined || typeof item !== 'object') {
      return item;
    }

    if (Array.isArray(item)) {
      return (item as any).map((a: any) => ObjectHelpers.deepCopy<any>(a));
    }

    return Object.keys(item).reduce((copy: T, key: string) => ({
      ...copy,
      [key]: ObjectHelpers.deepCopy(item[key])
    }), {} as T);
  }
}