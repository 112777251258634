import {SubModelBuilder} from './SubModelBuilder';
import {FieldIfBuilder} from './FieldIfBuilder';
import {OptionInfoBuilder} from './OptionInfoBuilder';
import {FieldIf} from '../../../declarations/meta/FieldIf';
import {Option} from '../../../declarations/meta/Option';

export class OptionBuilder extends SubModelBuilder<OptionInfoBuilder, Option> {

  constructor(parentBuilder: OptionInfoBuilder,
              label: Option['label'],
              description: Option['description'],
              relationObjectType: Option['relation_object_type'],
              value: Option['value'],
              onBuild: (buildResult: Option) => void) {
    super(parentBuilder,
      {
        label,
        description,
        relation_object_type: relationObjectType,
        value,
        field_ifs: [],
      },
      onBuild);
  }

  public withFieldIf(ifType: FieldIf['if_type'], operator?: FieldIf['operator']): FieldIfBuilder<OptionBuilder> {
    return new FieldIfBuilder(this, ifType, operator, fif => this.product.field_ifs?.push(fif));
  }

  public withIsAllToggle(isAllToggle: boolean = true): OptionBuilder {
    this.product.is_all_toggle = isAllToggle;
    return this;
  }

  public selected(value: boolean = true): OptionBuilder {
    this.product.selected = value;
    return this;
  }

}