import {InputType} from './InputType';

export enum FieldType {
  NAME = 'name',
  NUMERIC = 'numeric',
  ID = 'id',
  STRING = 'string',
  BOOLEAN = 'boolean',
  MAP_ID = 'map_id',
  INLINE = 'inline',
  TEXT = 'text',
  ARRAY = 'array',
  DECIMAL = 'decimal',
  IDENTIFIER = 'identifier',
  ACTION_BUTTON = 'action_button',
  URL = 'url',
  DYNAMIC_FIELDS = 'dynamic_fields',
  OPTION_STRING = 'option_string'
}

export const getFieldTypeFromInputType = (it: InputType): FieldType => {
  switch (it) {
    case InputType.SEARCH_SELECTOR: return FieldType.MAP_ID;
    case InputType.COMPARE_VALUE: return FieldType.STRING;
    case InputType.CHECK_ARRAY: return FieldType.ARRAY;
    case InputType.INLINE: return FieldType.INLINE;
    case InputType.INLINE_ARRAY: return FieldType.ARRAY;
    case InputType.OBJECT_REFERENCE: return FieldType.ARRAY;
    case InputType.REF_ARRAY: return FieldType.ARRAY;
    case InputType.REF_DYNAMIC_ARRAY: return FieldType.ARRAY;
    case InputType.SEARCH_SELECTOR_MULTIPLE: return FieldType.ARRAY;
    case InputType.TEXT_AREA: return FieldType.TEXT;
    case InputType.MAP_ID: return FieldType.MAP_ID;
    case InputType.IDENTIFIER: return FieldType.IDENTIFIER;
    case InputType.NUMBER: return FieldType.NUMERIC;
    case InputType.PRECISION_DATE: return FieldType.INLINE;
    case InputType.ACTION_BUTTON: return FieldType.ACTION_BUTTON;
    case InputType.IMAGE:
      console.warn('Unknown FieldType for InputType IMAGE');
    // noinspection FallThroughInSwitchStatementJS
    case InputType.INPUT:
    default:
      return FieldType.STRING;
  }
};