// noinspection JSUnusedGlobalSymbols
export enum ActionType {
  SET_PROP = 'set_prop',
  GET_COORDINATES = 'get_coordinates',
  STORE_CHANGES = 'store_changes',
  CLOSE_EDIT = 'close_edit',
  COPY_TEMPLATE_GROUP = 'copy_template_group',
  SCROLL_TO_TOP = 'scroll_to_top',
  REMOVE_NEW_ARRAY_ITEM = 'remove_new_array_item',
  GO_STATE = 'go_state',
  ADD_TO_PROP = 'add_to_prop',
  MOVE_TO = 'move_to',
  RESET_FORM = 'reset_form'
}