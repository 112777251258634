import React, {FC} from 'react';
import {styled, Typography} from '@material-ui/core';
import FlexContainer from './FlexContainer';
import PrimusLogo from './PrimusLogo';

export interface PrimusAppBrandProps {
  appName?: string;
  textColor?: 'initial' | 'inherit' | 'primary' | 'secondary' | 'textPrimary' | 'textSecondary' | 'error';
}

const Container = styled(FlexContainer)({
  display: 'inline',
  textAlign: 'center'
});

export const PrimusAppBrand: FC<PrimusAppBrandProps> = ({appName = '', textColor = 'initial'}) => (
  <Container vertical={false} fullHeight={false} fullWidth={false}>
    <PrimusLogo width="83px"/>
    <Typography variant="h5" component="span" color={textColor}>
      {appName}
    </Typography>
  </Container>
);

export default PrimusAppBrand;