import React, {FC, useEffect, useState} from 'react';
import {Avatar, Card, CardHeader, Theme} from '@material-ui/core';
import {Face} from '@material-ui/icons';
import {usePrimusApi} from '../hooks/PrimusApiProvider';
import {makeStyles} from '@material-ui/core/styles';

export interface ProfileMenuSectionProps {
  name?: string;
  username?: string;
  email?: string;
  imageId?: string;
  sticky?: boolean;
  elevation?: number;
}

const useStyles = makeStyles<Theme, Pick<ProfileMenuSectionProps, 'sticky'>>({
  container: {
    position: props => props.sticky ? 'sticky' : 'static',
    bottom: props => props.sticky ? 0 : 'initial'
  }
});

export const ProfileMenuSection: FC<ProfileMenuSectionProps> = ({
                                                           name,
                                                           username,
                                                           email,
                                                           imageId,
                                                           sticky = false,
                                                           elevation = 1
}) => {
  const classes = useStyles({sticky});
  const [image, setImage] = useState<string>('');
  const api = usePrimusApi();

  useEffect(() => {
    if (!image && imageId && api) {
      api.getImage(imageId)
        .then(img => setImage(URL.createObjectURL(img)))
        .catch(e => {
          console.error(e);
        });
    }
  }, [image, api, imageId]);

  return (
    <div className={classes.container}>
      <Card elevation={elevation} raised={elevation !== 0}>
        <CardHeader avatar={<Avatar src={image}><Face/></Avatar>}
                    title={name || username}
                    subheader={email}/>
      </Card>
    </div>
  );
};

export default ProfileMenuSection;