import {useEffect, useState} from 'react';

/**
 * A hook that detects whether or not the app is currently offline.
 * @return {boolean} True if the app is offline. False if online
 */
export const useOfflineEvent = (): boolean => {
  const [isOffline, setIsOffline] = useState<boolean>(!navigator.onLine);

  useEffect(() => {
    const onlineHandler = () => setIsOffline(false);
    const offlineHandler = () => setIsOffline(true);
    window.addEventListener('online', onlineHandler);
    window.addEventListener('offline', offlineHandler);
    return () => {
      window.removeEventListener('online', onlineHandler);
      window.removeEventListener('offline', offlineHandler);
    }
  });

  return isOffline;
}