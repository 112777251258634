/**
 * Use this to make a promise cancellable.
 * @example
 * const normalPromise = someAsyncTask();
 * const {promise, cancel} = createCancellationPromise(normalPromise);
 * const stopAsyncTask = () => cancel();
 * promise.then(result => handleResult(result))
 *        .catch(reason => {
 *          if (reason !== 'Cancelled') {
 *            // Handle error
 *          }
 *        });
 *
 * @param {Promise<T>} promise
 * @return {{promise: Promise<T>, cancel: () => void}}
 */
export const createCancellationPromise = <T>(promise: Promise<T>): { promise: Promise<T>, cancel: () => void } => {
  let cancel = () => {};
  const task = new Promise<T>((resolve, reject) => {
    cancel = () => reject('Cancelled');
    promise
      .then(resolve)
      .catch(reject)
      .finally(() => cancel = () => {});
  });
  return {
    promise: task,
    cancel
  };
}

export default createCancellationPromise;