import React, {FC} from 'react';
import Typography from '@material-ui/core/Typography';
import {Card, createStyles, styled, Theme} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import FlexContainer from '../../components/FlexContainer';
import PrimusLogo from '../../components/PrimusLogo';
import {PRIMUS_PRIMARY_DARK_COLOR} from '../../asset/colors';
import {useLoginLabels} from './PrimusEkulturLabelProvider';
import FlagButton, {Language} from '../../components/FlagButton';

export interface PrimusEkulturLoginContainerProps {
  onLanguageChanged?: (language: Language) => void
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  title: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    color: theme.palette.getContrastText(PRIMUS_PRIMARY_DARK_COLOR)
  }
}))

const Container = styled('main')({
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  overflow: 'hidden',
  zIndex: 0
});

const BackgroundTile = styled('span')({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  height: '40%',
  zIndex: 1,
  backgroundColor: PRIMUS_PRIMARY_DARK_COLOR
});

const ContentWrapper = styled(FlexContainer)(({theme}) => ({
  position: 'relative',
  boxSizing: 'border-box',
  padding: `10% ${theme.spacing(4)}px`,
  zIndex: 3
}));

const ContentCard = styled(Card)(({theme}) => ({
  overflow: 'visible',
  width: '100%',
  minHeight: '300px',
  margin: theme.spacing(4, 0)
}));

const ContentContainer = styled(FlexContainer)(({theme}) => ({
  boxSizing: 'border-box',
  padding: theme.spacing(3)
}));

export const PrimusEkulturLoginContainer: FC<PrimusEkulturLoginContainerProps> = ({children, onLanguageChanged}) => {
  const classes = useStyles();
  const labels = useLoginLabels();

  return (
    <Container>

      <BackgroundTile/>

      <ContentWrapper justify="space-between">
        <Typography variant={'h6'} component={'h1'} className={classes.title}>
          <PrimusLogo width="205px"/>
          { labels.appTitle }
        </Typography>

        <ContentCard elevation={3}>
          <ContentContainer justify="space-evenly">

            { children }

          </ContentContainer>
        </ContentCard>

        {
          onLanguageChanged &&
          <FlexContainer vertical={false} fullHeight={false} justify="center">
            <FlagButton language="EN"
                        onClick={() => onLanguageChanged && onLanguageChanged('EN')}/>
            <FlagButton language="NO"
                        onClick={() => onLanguageChanged && onLanguageChanged('NO')}/>
            <FlagButton language="SV"
                        onClick={() => onLanguageChanged && onLanguageChanged('SV')}/>
          </FlexContainer>
        }

        <Typography variant="caption">
          { labels.contactSupport }
        </Typography>

      </ContentWrapper>
    </Container>
  );
};

export default PrimusEkulturLoginContainer;