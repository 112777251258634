import React, {FC, useEffect} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import PrimusEkulturLoginController from './PrimusEkulturLoginController';
import PrimusEkulturLoginCallback from './PrimusEkulturLoginCallback';
import PrimusEkulturLogout from './PrimusEkulturLogout';
import {PrimusEkulturLoginLabels, setInitialUrl} from './PrimusEkulturLoginGlobals';
import PrimusEkulturLabelProvider from './PrimusEkulturLabelProvider';
import {PrimusEkulturLoginContainerProps} from './PrimusEkulturLoginContainer';

export interface PrimusEkulturLoginProps extends Pick<PrimusEkulturLoginContainerProps, 'onLanguageChanged'> {
  labels?: Partial<PrimusEkulturLoginLabels>;
  onLogin?: () => void;
  onLogout?: () => void;
}

export const PrimusEkulturLogin: FC<PrimusEkulturLoginProps> = ({
                                                                 children,
                                                                 labels,
                                                                 onLogin,
                                                                 onLogout,
                                                                 onLanguageChanged
                                                               }) => {

  useEffect(() => {
    setInitialUrl();
  }, []);

  return (
    <PrimusEkulturLabelProvider labels={labels}>
      <Switch>

        <Route path="/oauth2/callback" exact>
          <PrimusEkulturLoginCallback/>
        </Route>

        <Route path="/logout" exact>
          <PrimusEkulturLogout onLogout={onLogout}/>
        </Route>

        <Route path="/">
          <PrimusEkulturLoginController onLogin={onLogin}
                                        onLanguageChanged={onLanguageChanged}>
            {children}
          </PrimusEkulturLoginController>
        </Route>

        <Route path="*">
          <Redirect to="/"/>
        </Route>

      </Switch>
    </PrimusEkulturLabelProvider>
  );
};

export default PrimusEkulturLogin;