import {SubModelBuilder} from './SubModelBuilder';
import {ReferenceBuilder} from './ReferenceBuilder';
import {Selector} from '../../../declarations/meta/Selector';

export class SelectorBuilder extends SubModelBuilder<ReferenceBuilder, Selector> {

  constructor(parentBuilder: ReferenceBuilder, type: Selector['selector_type'], view: string, buttonLabel: string, hideButton: boolean = false, onBuild: (buildResult: Selector) => void) {
    super(parentBuilder, {
      checked_filters: [],
      filters: [],
      select_button_text: buttonLabel,
      selector_button_hidden: hideButton,
      selector_type: type,
      view
    } as Selector, onBuild);
  }

  public withFilter(fieldName?: string, sourceFieldName?: string, value?: string): SelectorBuilder {
    this.product.filters!.push({field: fieldName, source_field: sourceFieldName, value});
    return this;
  }

  public withCheckedFilter(fieldName: string, sourceFieldName: string): SelectorBuilder {
    this.product.checked_filters?.push({field: fieldName, source_field: sourceFieldName});
    return this;
  }

}