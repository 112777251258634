import {SubModelBuilder} from './SubModelBuilder';
import {ReferenceBuilder} from './ReferenceBuilder';
import {CopyField} from '../../../declarations/meta/CopyField';
import {AddNewParams} from '../../../declarations/meta/AddNewParams';

export class ParamsBuilder extends SubModelBuilder<ReferenceBuilder, AddNewParams> {

  constructor(parentBuilder: ReferenceBuilder, title: string, objectType: string, onBuild: (buildResult: AddNewParams) => void) {
    super(parentBuilder, {
      add_button_title: title,
      new_object_type: objectType,
      copy_fields: []
    }, onBuild);
  }

  public withFullObjectEdit(value: boolean = true): ParamsBuilder {
    this.product.full_object_edit = value;
    return this;
  }

  public withCopyField(copyField: CopyField): ParamsBuilder {
    this.product.copy_fields!.push(copyField);
    return this;
  }

  public withNameFieldPath(path: string): ParamsBuilder {
    this.product.name_field_path = path;
    return this;
  }
}