export interface PrimusEkulturLoginLabels {
  appTitle: string;
  contactSupport: string;
  startLoginButtonText: string;
  loginFailed: string;
  controllingCredentials: string;
  completeLoginInOpenWindow: string;
  appOffline: string;
  tryAgainButtonLabel: string;
  loggingOut: string;
  logoutFailed: string;
  selectServerInputLabel: string;
  selectServerButtonLabel: string;
  loggingIn: string;
  loginTimedOut: string;
  abortLoginButtonLabel: string;
}

export const DEFAULT_LABELS: PrimusEkulturLoginLabels = {
  appTitle: 'PWA',
  contactSupport: 'Contact support if you need help',
  startLoginButtonText: 'Log in with eKultur',
  loginFailed: 'Unable to log in',
  controllingCredentials: 'Controlling credentials',
  completeLoginInOpenWindow: 'Please complete the login-process in the opened window',
  appOffline: 'Unable to log in or out while offline',
  tryAgainButtonLabel: 'Try again',
  loggingOut: 'Logging out...',
  logoutFailed: 'An error occurred logging out',
  selectServerInputLabel: 'Select server',
  selectServerButtonLabel: 'Select server',
  loggingIn: 'Logging in...',
  loginTimedOut: 'Login failed. Session timed out.',
  abortLoginButtonLabel: 'Cancel'
};

export const LOGIN_SESSION_TIME = 10 * 60 * 1000;

const LOGIN_STARTED_SESSION_KEY = 'primus.ekultur.login.started';
const LOGIN_STARTED_SESSION_VALUE = 'true';

const INITIAL_URL_SESSION_KEY = 'primus.ekultur.login.initial.url';

export const isLoginStarted = (): boolean => window.sessionStorage.getItem(LOGIN_STARTED_SESSION_KEY) === LOGIN_STARTED_SESSION_VALUE;
export const startLoginSession = () => window.sessionStorage.setItem(LOGIN_STARTED_SESSION_KEY, LOGIN_STARTED_SESSION_VALUE);
export const stopLoginSession = () => window.sessionStorage.removeItem(LOGIN_STARTED_SESSION_KEY);

export const setInitialUrl = (url?: string) => window.sessionStorage.setItem(INITIAL_URL_SESSION_KEY, url || window.location.href);
export const goToInitialUrl = () => {
  const url = window.sessionStorage.getItem(INITIAL_URL_SESSION_KEY);
  window.sessionStorage.removeItem(INITIAL_URL_SESSION_KEY);
  if (url) {
    window.location.replace(url);
  } else {
    window.location.reload();
  }
}
export const unsetInitialUrl = () => window.sessionStorage.removeItem(INITIAL_URL_SESSION_KEY);

export const CALLBACK_URI = `${window.location.origin}/oauth2/callback`;