import React, {FC, useEffect, useRef, useState} from 'react';
import {usePrimusApi} from '../../hooks/PrimusApiProvider';
import PrimusEkulturLoginContainer, {PrimusEkulturLoginContainerProps} from './PrimusEkulturLoginContainer';
import {Button} from '@material-ui/core';
import PrimusEkulturLoading from './PrimusEkulturLoading';
import PrimusEkulturLoginOffline from './PrimusEkulturLoginOffline';
import {useOfflineEvent} from '../../hooks/UseOfflineEvent';
import PrimusEkulturSelectPrimusInstance from './PrimusEkulturSelectPrimusInstance';
import {
  CALLBACK_URI,
  isLoginStarted,
  LOGIN_SESSION_TIME,
  startLoginSession,
  stopLoginSession, unsetInitialUrl
} from './PrimusEkulturLoginGlobals';
import {useLoginLabels} from './PrimusEkulturLabelProvider';

export interface PrimusEkulturLoginControllerProps extends Pick<PrimusEkulturLoginContainerProps, 'onLanguageChanged'>  {
  onLogin?: () => void;
}

/**
 * <h1>Requirements:</h1>
 * <ul>
 *   <li>An implementation of the PrimusApi is provided (and truthy) higher in the component-tree</li>
 *   <li>The route `/oauth2/callback` renders the callback-component</li>
 * </ul>
 * @param {React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined} children
 * @param onLogin
 * @param onLanguageChanged
 * @return {JSX.Element}
 * @constructor
 */
export const PrimusEkulturLoginController: FC<PrimusEkulturLoginControllerProps> = ({children, onLogin, onLanguageChanged}) => {
  const api = usePrimusApi()!;
  const labels = useLoginLabels();
  const isOffLine = useOfflineEvent();
  const [authenticated, setAuthenticated] = useState<boolean>(api.isAuthenticated());
  const [loading, setLoading] = useState<boolean>(false);
  const [loginFailed, setLoginFailed] = useState<string>('');

  const externalWindowRef = useRef<Window | null>(null);

  const abortLogin = (errorMessage: string = '') => {
    setLoginFailed(errorMessage);
    stopLoginSession();
    unsetInitialUrl();
    setLoading(false);
  };

  const handleStartLogin = () => {
    startLoginSession();
    setLoading(true);

    const windowRef = window.open(window.location.href, "_blank");
    externalWindowRef.current = windowRef;

    if (!!windowRef) {
      const handleMessage = (message: MessageEvent) => {
        if (message?.data?.hasOwnProperty('loginSuccess')) {
          clearTimeout(timeout);
          const auth = message.data.loginSuccess
          setAuthenticated(auth);
          if (!auth) {
            setLoginFailed(labels.loginFailed);
          }

          windowRef.close();
          window.removeEventListener('message', handleMessage);
          stopLoginSession();
          setLoading(false);
        }
      };

      const timeout = setTimeout(() => {
        window.removeEventListener('message', handleMessage);
        if (windowRef) {
          windowRef.close();
        }
        abortLogin(labels.loginTimedOut);
      }, LOGIN_SESSION_TIME);

      window.addEventListener('message', handleMessage);
    } else {
      console.error('Unable to open window: ', windowRef);
      abortLogin(labels.loginFailed);
    }
  };

  useEffect(() => {
    // Removes state and closes the login-window if the user refreshes the page.
    // Prevents the user from being "stuck" in a login-loop
    const unsetSessionDataHandler = () => {
      stopLoginSession();
      unsetInitialUrl();
      if (externalWindowRef?.current && !externalWindowRef.current.closed) {
        externalWindowRef.current.close();
      }
    }
    window.addEventListener('unload', unsetSessionDataHandler);
    return () => window.removeEventListener('unload', unsetSessionDataHandler);
  }, [externalWindowRef]);

  useEffect(() => {
    if (isLoginStarted()) {
      api.loginIDP(CALLBACK_URI, window.location.origin);
    } else {
      const refreshToken = () => {
        setLoading(true);
        api.refreshToken()
          .then(setAuthenticated)
          .finally(() => setLoading(false));
      };

      window.addEventListener('load', refreshToken);
      return () => window.removeEventListener('load', refreshToken);
    }
    return () => {};
  }, [api]);



  let content = (
    <>
      <span/>
      <Button type="button"
              variant="contained"
              color="primary"
              name={labels.startLoginButtonText}
              onClick={handleStartLogin}>
        {labels.startLoginButtonText}
      </Button>
      <span/>
    </>
  );


  if (loginFailed) {
    content = (
      <PrimusEkulturLoading loading={false} text={loginFailed} textColor="error">
        <Button type="button"
                variant="contained"
                color="primary"
                name={labels.tryAgainButtonLabel}
                onClick={() => window.location.reload()}>
          {labels.tryAgainButtonLabel}
        </Button>
      </PrimusEkulturLoading>
    );

  } else if (loading) {
    content = (
      <PrimusEkulturLoading loading={true}
                            text={isLoginStarted() ? labels.completeLoginInOpenWindow : labels.controllingCredentials}>
        <Button type="button"
                variant="contained"
                color="primary"
                name={labels.abortLoginButtonLabel}
                onClick={() => abortLogin()}>
          {labels.abortLoginButtonLabel}
        </Button>
      </PrimusEkulturLoading>
    );

  } else if (authenticated) {
    return (
      <PrimusEkulturSelectPrimusInstance onLogin={onLogin}>
        {children}
      </PrimusEkulturSelectPrimusInstance>
    );

  } else if (isOffLine) {
    return <PrimusEkulturLoginOffline/>
  }

  return (
    <PrimusEkulturLoginContainer onLanguageChanged={onLanguageChanged}>
      {content}
    </PrimusEkulturLoginContainer>
  );
};

export default PrimusEkulturLoginController;