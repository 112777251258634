export enum InputType {
  MAP_ID = 'map_id',
  REF_ARRAY = 'ref_array',
  REF_DYNAMIC_ARRAY = 'ref_dynamic_array',
  TEXT_AREA = 'text_area',
  INPUT = 'input',
  PRECISION_DATE = 'precision_date',
  IDENTIFIER = 'identifier',
  NUMBER = 'number',
  CHECKBOX = 'checkbox',
  CHECK_ARRAY = 'check_array',
  SEARCH_SELECTOR = 'search_selector',
  SEARCH_SELECTOR_MULTIPLE = 'search_selector_multiple',
  IMAGE = 'image',
  OBJECT_REFERENCE = 'object_reference',
  INLINE = 'inline',
  INLINE_ARRAY = 'inline_array',
  COMPARE_VALUE = 'compare_value',
  ACTION_BUTTON = 'action_button',
}