import React, {Context, FC, useContext} from 'react';
import {PrimusApi} from '../services/PrimusApi';

export interface PrimusApiProviderProps {
  apiInstance: PrimusApi;
}

const primusApi: Context<PrimusApi | null> = React.createContext<PrimusApi | null>(null);

export const usePrimusApi = (): PrimusApi | null => useContext(primusApi);

export const PrimusApiProvider: FC<PrimusApiProviderProps> = ({children, apiInstance}) => (
  <primusApi.Provider value={apiInstance}>
    { children }
  </primusApi.Provider>
);

export default PrimusApiProvider;