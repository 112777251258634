import React, {FC, ReactElement, MouseEvent} from 'react';
import {AppBar, IconButton, styled, Toolbar} from '@material-ui/core';
import {Menu} from '@material-ui/icons';
import FlexContainer from '../components/FlexContainer';
import HeaderSearch, {HeaderSearchProps} from '../components/HeaderSearch';

export interface AppHeaderBarProps extends Partial<Pick<HeaderSearchProps, 'onSearch'>> {
  color?: 'default' | 'inherit' | 'primary' | 'secondary' | 'transparent';
  position?: 'absolute' | 'fixed' | 'relative' | 'static' | 'sticky';
  actionButtonIcon?: ReactElement;
  onAction?: (event: MouseEvent) => void;
  brandContent?: ReactElement;
  endContent?: ReactElement;
  enableSearchHeader?: boolean;
  searchInputLabel?: string;
}

const HeaderSearchSpacer = styled('div')(({theme}) => ({
  width: theme.spacing(6),
  height: theme.spacing(6)
}));

export const AppHeaderBar: FC<AppHeaderBarProps> = ({
                                                      children,
                                                      color,
                                                      position = 'relative',
                                                      onAction,
                                                      actionButtonIcon = <Menu/>,
                                                      brandContent,
                                                      endContent,
                                                      enableSearchHeader = false,
                                                      searchInputLabel,
                                                      onSearch
}) => (
  <AppBar position={position} color={color}>
    <Toolbar variant="dense">
      <FlexContainer vertical={false} fullHeight={false} justify="space-between">
        <FlexContainer vertical={false} fullHeight={false} fullWidth={false} justify="flex-start">

          {
            (!!actionButtonIcon && !!onAction) &&
            <IconButton edge="start" color="inherit" aria-label="menu" onClick={e => onAction && onAction(e)}>
              { actionButtonIcon }
            </IconButton>
          }

          { !!brandContent && brandContent }

          { children }

        </FlexContainer>

        { !!endContent && endContent }
      </FlexContainer>
      {
        enableSearchHeader &&
        <>
          <HeaderSearchSpacer/>
          <HeaderSearch placeholder={searchInputLabel}
                        onSearch={q => onSearch && onSearch(q)}/>
        </>
      }
    </Toolbar>
  </AppBar>
);

export default AppHeaderBar;