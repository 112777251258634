import React, {FC, useEffect, useMemo, useState} from 'react';
import Form from '@rjsf/material-ui';
import {PrimusModelToJsonSchemaTransformer} from '../../asset/js/PrimusModelToJsonSchemaTransformer';
import PrecisionDateField from './customFields/PrecisionDateField';
import SearchSelector, {SearchSelectorMultiple} from './customFields/SearchSelector';
import {Field, FormProps, FormValidation, Widget} from '@rjsf/core';
import ArrayFieldTemplate from './templates/ArrayFieldTemplate';
import {CustomFieldTypes} from '../../asset/js/JsonSchemaFieldType';
import {PrimusApi} from '../../services/PrimusApi';
import {Meta} from '../../declarations/Meta';
import {Model} from '../../declarations/Model';
import {JsonSchemaForm} from '../../declarations/JsonSchemaForm';
import {ValidationMessageFactory, validatePrimusField} from '../../asset/js/PrimusFieldValidation';
import IdentifierField from './customFields/IdentifierField';
import {createMuiTheme, ThemeProvider} from '@material-ui/core';
import {PrimusLocale} from '../../declarations/PrimusLocale';
import CustomSelectWidget from './customWidgets/CustomSelectWidget';

const theme = createMuiTheme({
  props: {
    MuiTextField: {
      variant: 'filled'
    },
  },
  overrides: {
    MuiMenuItem: {
      root: {
        whiteSpace: 'unset',
        wordBreak: 'break-word'
      }
    }
  }
});

export interface PrimusFormContext {
  api: PrimusApi;
  meta: Meta;
  generateIdentifierParams: {
    superobjectTypeId: string;
    collectionId?: string;
    contextId?: string;
  };
  locale: PrimusLocale
}

export interface PrimusFormProps extends Pick<FormProps<Model>, 'onError' | 'onFocus' | 'onBlur' | 'showErrorList' | 'disabled' | 'noHtml5Validate' | 'noValidate' | 'liveValidate'> {
  primusApi: PrimusApi;
  model: Model;
  meta?: Meta;
  loading?: boolean;
  renderLoader?: React.ReactElement;
  onTransformerError?: (reason: any) => void;
  validationErrorMessageFactory?: ValidationMessageFactory;
  locale?: PrimusLocale;
  onSubmit?: (model: Model) => void;
  onChange?: (model: Model) => void;
}

const customFields: { [name in CustomFieldTypes]: Field } = {
  precisionDate: PrecisionDateField,
  searchSelector: SearchSelector,
  searchSelectorMultiple: SearchSelectorMultiple,
  identifier: IdentifierField
};

const customWidgets: {[name: string]: Widget} = {
  SelectWidget: CustomSelectWidget
}

const removeNullValues = (model: Model) => {
  return Object.keys(model)
    .reduce((accumulatedModel, modelProperty) => {
      const val = model[modelProperty];
      if (val !== null) {
        accumulatedModel[modelProperty] = (typeof val === 'object' && !Array.isArray(val)) ? removeNullValues(val) : val;
      }
    return accumulatedModel;
  }, {} as Model)
};

export const PrimusForm: FC<PrimusFormProps> = ({
                                           children,
                                           primusApi,
                                           model,
                                           meta,
                                           renderLoader,
                                           loading,
                                           onTransformerError,
                                           validationErrorMessageFactory,
                                           showErrorList = false,
                                           locale,
                                           onSubmit,
                                           onChange,
                                           ...rest
}) => {
  // Create states
  const metadata = useMemo<Meta>(() => meta || model.$$meta, [meta, model]);
  const [form, setForm] = useState<JsonSchemaForm>();
  const [value, setValue] = useState<Model>();
  const [transformer, setTransformer] = useState<PrimusModelToJsonSchemaTransformer | null>(null);
  const [selectedLocale, setSelectedLocale] = useState<PrimusLocale>('no');

  // Build form-context
  const fieldMetas = useMemo(() => transformer?.getFieldMetas(), [transformer]);

  const generateIdentifierParams = useMemo<PrimusFormContext['generateIdentifierParams']>(() => ({
    superobjectTypeId: model?.superobject_type_id || '',
    collectionId: model && model.meta_type !== 'spectrum_procedure' ? model?.collection?.collection_id : '',
    contextId: !!model?.contexts?.length ? model.contexts[0].context_id : '',
  }), [model]);

  const primusFormContext = useMemo<PrimusFormContext>(
    () => ({
      api: primusApi,
      meta: fieldMetas,
      generateIdentifierParams,
      locale: selectedLocale || 'no'
    } as PrimusFormContext),
    [primusApi, fieldMetas, generateIdentifierParams, selectedLocale]);

  // Define handlers and operations

  const validateForm = (values: Model, errors: FormValidation): FormValidation => {
    if (!values || !primusFormContext.meta) {
      return errors;
    }

    Object.keys(values)
      .map(prop => {
        while (errors[prop]?.__errors?.length) {
          errors[prop].__errors.pop();
        }
        return prop;
      })
      .filter(prop => !prop.startsWith('$') && primusFormContext?.meta[prop])
      .map(prop => ({
        name: prop,
        fieldMeta: primusFormContext.meta[prop],
        value: values[prop],
        errors: errors[prop]
      }))
      .forEach(field => {
        if (typeof field.value === 'object' && !Array.isArray(field.value)) {
          validateForm(field.value, field.errors as FormValidation);
        } else {
          const err = validatePrimusField(field.fieldMeta, field.value, validationErrorMessageFactory) || {};
          Object.keys(err).map(key => err[key]).forEach(msg => field.errors.addError(msg));
        }
      });
    return errors;
  }

  const transformErrors = (errors: any) => errors.map((error: any) => {
    // This is to remove any errors added by the framework.
    // All errors will be handled manually
    error.message = '';
    return error;
  });

  // React to changes

  useEffect(() => {
    if (!!locale) {
      setSelectedLocale(locale);
    } else {
      const browserLocale = (navigator.language || '').toLowerCase();
      if (browserLocale?.endsWith('no')) {
        setSelectedLocale('no');
      } else if (browserLocale?.endsWith('se')) {
        setSelectedLocale('sv');
      } else {
        setSelectedLocale('en');
      }
    }
  }, [locale]);

  useEffect(() => {
    PrimusModelToJsonSchemaTransformer
      .createInstance(primusApi, selectedLocale)
      .then(setTransformer)
      .catch(onTransformerError);
  }, [primusApi, selectedLocale]);

  useEffect(() => {
    setValue(removeNullValues(model));
  }, [model]);

  useEffect(() => {
    if (transformer) {
      transformer
        .transform(model, metadata)
        .then(setForm)
        .catch(onTransformerError);
    }
  }, [model, metadata, transformer]);

  // Render form

  if (!form || !transformer || !!loading) {
    return <>{renderLoader}</>;
  }

  return (
    <ThemeProvider theme={theme}>
      <Form schema={form.schema}
            uiSchema={form.uiSchema}
            formData={value}
            onSubmit={({formData}) => onSubmit && onSubmit(formData)}
            fields={customFields}
            widgets={customWidgets}
            onChange={({formData}) => {
              setValue(formData);
              onChange && onChange(formData);
            }}
            formContext={primusFormContext}
            ArrayFieldTemplate={ArrayFieldTemplate}
            noHtml5Validate
            validate={validateForm}
            showErrorList={showErrorList}
            transformErrors={transformErrors}
            {...rest}>
        { children }
      </Form>
    </ThemeProvider>
  );
};

export default PrimusForm;