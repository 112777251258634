import {PrimusAuthService} from './PrimusAuthService';
import {SearchParams} from '../declarations/SearchParams';
import {SearchResult} from '../declarations/SearchResult';
import {Artifact} from '../declarations/Artifact';
import {ModelsResponse} from '../declarations/ModelsResponse';
import {Model} from '../declarations/Model';
import {UserConfig} from '../declarations/UserConfig';
import {PrimusLocale} from '../declarations/PrimusLocale';
import {PrimusTranslation} from '../declarations/PrimusTranslation';
import {ArtifactView} from '../declarations/ArtifactView';

export class PrimusApiException extends Error {
  public readonly status: number;
  public readonly response: Response;
  public readonly bodyText: string | null;
  public readonly bodyJson: object | null;
  public readonly bodyBlob: any;

  constructor(response: Response, bodyText: string | null, bodyJson: object | null, bodyBlob: any) {
    super(`${response.status}: ${response.statusText}`);
    this.status = response.status;
    this.response = response;
    this.bodyText = bodyText;
    this.bodyJson = bodyJson;
    this.bodyBlob = bodyBlob;
  }

  public static async fromResponse(response: Response): Promise<PrimusApiException> {
    let txt: string | null;
    let json: object | null;
    let blob: any;

    try {
      txt = await response.text();
    } catch (e) {
      console.error(e);
      txt = null;
    }

    try {
      json = await response.json();
    } catch (e) {
      console.error(e);
      json = null;
    }

    try {
      blob = await response.blob();
    } catch (e) {
      console.error(e);
      blob = null;
    }

    return new PrimusApiException(response, txt, json, blob);
  }
}






export abstract class PrimusApi extends PrimusAuthService {

  protected constructor(gatewayUri: string, loginHost: string, primusAppId: string) {
    super(gatewayUri, loginHost, primusAppId);
  }

  protected async checkOk(p: Promise<Response>): Promise<Response> {
    const res: Response = await p;
    if (!res?.ok) {
      throw await PrimusApiException.fromResponse(res);
    }
    return res;
  }

  protected async json<T>(p: Promise<Response>): Promise<T> {
    const res: Response = await this.checkOk(p);
    return res.json();
  }

  public async search(searchParams: SearchParams): Promise<SearchResult> {
    return this.json(this.put('/search', searchParams));
  }

  public async createArtifact(objectType: string): Promise<Artifact> {
    return this.json(this.get(`/create_artifact/${objectType}`));
  }

  public async getArtifact(artifactId: string): Promise<Artifact> {
    return this.json(this.get(`/get_artifact/${artifactId}`));
  }

  public async copyArtifact(artifactId: string): Promise<Artifact> {
    return this.json(this.get(`/copy_artifact/${artifactId}`));
  }

  public async saveArtifact(artifact: Artifact): Promise<Artifact> {
    return this.json(this.put('/put_artifact', artifact));
  }

  public async deleteArtifact(artifactId: string): Promise<void> {
    await this.checkOk(this.delete(`/delete_artifact/${artifactId}`));
  }

  public async saveSubArtifact(subArtifact: Artifact): Promise<string> {
    if (!subArtifact.artifact_id) {
      subArtifact._create = true;
    }
    const {artifact_id} = await this.json<Artifact>(this.put('/sub_artifact', subArtifact));
    return artifact_id;
  }

  public async uploadImage(artifactId: string, image: Blob, fileTypeId?: string): Promise<void> {
    const uploadHeaders = new Headers();
    // Remove default-header by setting falsy value, so that browser sets boundary automatically
    uploadHeaders.append('Content-Type', '');

    const data = new FormData();
    data.append('file', image);

    const {artifact_id} = await this.json(this.post(
      `/upload/image`,
      data,
      {headers: uploadHeaders} as Request,
      false
    ));

    const imageArtifact = await this.getArtifact(artifact_id);
    if (!!fileTypeId && imageArtifact?.code_type_id !== fileTypeId) {
      imageArtifact.code_type_id = fileTypeId;
      await this.saveArtifact(imageArtifact);
    }

    const imageItem = await this.createArtifact('ImageItem');
    imageItem.context_id = artifactId;
    imageItem.image_id = imageArtifact.artifact_id;
    await this.saveSubArtifact(imageItem);
  }

  public async getImage(imageId: string): Promise<Blob> {
    const res = await this.checkOk(
      this.get(`/image/${imageId}`, {
        headers: new Headers({
          'Accept': 'image/*'
        })
      } as Request)
    );
    return res.blob();
  }

  public async getModels(): Promise<ModelsResponse> {
    return this.json(this.get('/models'));
  }

  public async getModel(modelName: string, alternateViewName?: string): Promise<Model> {
    let params = '';
    if (alternateViewName) {
      params = `?alternateViewName=${encodeURI(alternateViewName)}`;
    }
    const data: ModelsResponse = await this.json(this.get(`/model/${modelName}${params}`));
    return data ? data[modelName] : data;
  }

  public async getUserConfig(): Promise<UserConfig> {
    const res: { user: UserConfig } = await this.json(this.get('/user_config'));
    return !!res ? res.user : res;
  }

  public async getArtifactByBarcode(barcode: string): Promise<Artifact> {
    return this.json(this.get(`/get_artifact_by_barcode/${barcode}`));
  }

  public async getArtifactByQrCode(qrCode: string): Promise<Artifact> {
    return this.json(this.put('/get_artifact_by_qr_code', qrCode));
  }

  public async getTranslations(lang: PrimusLocale = 'no'): Promise<PrimusTranslation> {
    return this.json(this.get(`/translations?lang=${lang}`));
  }

  public async getArtifactView(artifactId: string): Promise<ArtifactView> {
    return this.json(this.get(`/view/${artifactId}`));
  }

  public async generateIdentificationNumber(superObjectId: string, collectionId?: string, contextId?: string): Promise<string> {
    let params = '';
    if (collectionId || contextId) {
      params = '?';
      if (collectionId) params += 'collection_id' + encodeURI(collectionId);
      if (collectionId && contextId) params += '&';
      if (contextId) params += 'context_id' + encodeURI(contextId);
    }
    const {identifier} = await this.json<{identifier: string}>(this.get(`/get_next_identifier/${superObjectId}${params}`));
    return identifier;
  }

}