import React, {FC, useCallback, useEffect, useRef, useState, KeyboardEvent} from 'react';
import {debounce, IconButton, styled, TextField} from '@material-ui/core';
import {Close, Search} from '@material-ui/icons';

export interface HeaderSearchProps {
  onSearch: (query: string) => void;
  placeholder?: string;
}

const SearchField = styled(TextField)(({theme}) => ({
  position: 'absolute',
  backgroundColor: theme.palette.background.paper,
  transition: 'all 300ms ease-in-out, width 150ms ease-in-out',
  width: theme.spacing(4),
  borderRadius: theme.spacing(2),
  overflow: 'hidden',
  right: theme.spacing(1),
  '& MuiButtonBase-root': {
    padding: '3px',
    fontSize: '1.125rem'
  },
  '&.open': {
    borderRadius: 0,
    width: '100%',
    right: 0
  }
}));

const AdornmentButton = styled(IconButton)(({theme}) => ({
  transition: 'all 300ms ease-in-out',
  '&.closed': {
    padding: theme.spacing(1/2),
    fontSize: '1.125rem',
  }
}));

export const HeaderSearch: FC<HeaderSearchProps> = ({
                                                      onSearch,
                                                      placeholder
}) => {
  const searchFieldRef = useRef<HTMLInputElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [query, setQuery] = useState<string>('');

  const updateQuery = useCallback(debounce(onSearch, 400), [onSearch]);

  const clearSearch = () => {
    setQuery('');
    if (onSearch) {
      onSearch('');
    }
  }

  const openSearch = () => {
    setOpen(true);
    if (searchFieldRef?.current) {
      searchFieldRef.current.focus();
    }
  };

  const closeSearch = () => {
    setOpen(false);
    if (searchFieldRef?.current) {
      searchFieldRef.current.blur();
    }
  };

  const handleSearchIconClicked = () => {
    if (!open) {
      openSearch();
    } else if (!!onSearch && !!query) {
      onSearch(query);
    }
  };

  const handleCloseIconClicked = () => {
    if (open && !!query) {
      clearSearch();
    } else {
      closeSearch();
    }
  };

  const handleKeyup = (event: KeyboardEvent) => {
    if (event.key === 'Enter' && !!updateQuery) {
      updateQuery(query);
    } else if (event.key === 'Escape' && open) {
      closeSearch();
    }
  };

  useEffect(() => {
    if (!!updateQuery && !!query) {
      updateQuery(query);
    }
  }, [query, updateQuery]);

  const adornmentButtonClass = open ? '' : 'closed';

  return (
    <SearchField inputRef={searchFieldRef}
                 value={query}
                 className={open ? 'open' : ''}
                 onChange={event => setQuery(event.target.value as string)}
                 placeholder={placeholder}
                 onKeyUp={handleKeyup}
                 InputProps={
                   {
                     startAdornment: (
                       <AdornmentButton type="button"
                                        className={adornmentButtonClass}
                                        onClick={handleSearchIconClicked}>
                         <Search/>
                       </AdornmentButton>
                     ),
                     endAdornment: (
                       <AdornmentButton type="button"
                                        className={adornmentButtonClass}
                                        onClick={handleCloseIconClicked}>
                         <Close/>
                       </AdornmentButton>
                     )
                   }
                 }/>
  );
};

export default HeaderSearch;