import {SubModelBuilder} from './SubModelBuilder';
import {MetaFieldBuilder} from './MetaFieldBuilder';
import {ValidationCompare} from '../../../declarations/meta/ValidationCompare';
import {Validation} from '../../../declarations/meta/Validation';

export class ValidationBuilder extends SubModelBuilder<MetaFieldBuilder, Validation> {

  constructor(parentBuilder: MetaFieldBuilder, onBuild: (buildResult: Validation) => void) {
    super(parentBuilder, {} as Validation, onBuild);
  }

  public withMax(max: number = 5000): ValidationBuilder {
    this.product.max_length = max;
    return this;
  }

  public withMin(min: number = 0): ValidationBuilder {
    this.product.min_length = min;
    return this;
  }

  public withRegEx(regex: RegExp | string): ValidationBuilder {
    this.product.reg_exp = new RegExp(regex).source;
    return this;
  }

  public withUsername(value: boolean = true): ValidationBuilder {
    this.product.username = value;
    return this;
  }

  public withCompareField(fieldName: string, comparator: ValidationCompare['comparator']): ValidationBuilder {
    this.product.compare = {
      field: fieldName,
      comparator
    };
    return this;
  }

}