import React, {FC, ReactElement, ReactNode, useState} from 'react';
import {Badge, BottomNavigation, BottomNavigationAction, createStyles, Paper, Theme} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

export interface AppFooterBarAction {
  /**
   * The ID of the button.
   * Must be unique within the context of a list.
   * Used this to choose what action should be provided on a click
   */
  id: string;
  /**
   * The label to display
   */
  label: string;
  /**
   * The Icon to display
   */
  icon: ReactElement;
  /**
   * A Badge will be displayed in the upper right corner of the button's icon,
   * if this is provided
   */
  badgeContent?: ReactNode;
  /**
   * Whether the button is disabled or not
   */
  disabled?: boolean;
}

export interface AppFooterBarProps {
  /**
   * Whether or not to always display the labels
   */
  showLabels?: boolean;
  /**
   * Disable all action buttons
   */
  disabled?: boolean;
  /**
   * The ID of the AppFooterBarAction that should be selected by default
   */
  defaultSelectionId?: string;
  /**
   * The list of items to display.
   * MUST be between 3 and 5 items
   */
  actionList: Array<AppFooterBarAction>;
  /**
   * Callback when one of the buttons are clicked/selected.
   * @param {string} id The ID of the selected button.
   */
  onAction?: (id: string) => void;
  /**
   * The color of the badge, if visible
   */
  badgeColor?: 'primary' | 'secondary' | 'default' | 'error';
  /**
   * The elevation of the app-bar.
   * Default is 4.
   * Set to 0 to remove
   */
  elevation?: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    BottomNavigationAction: {
      color: theme.palette.text.primary,
      '&.Mui-disabled': {
        color: theme.palette.text.disabled
      }
    },
    Badge: {
      top: '4px'
    }
  })
);

export const AppFooterBar: FC<AppFooterBarProps> = ({
                                                      showLabels = true,
                                                      disabled = false,
                                                      defaultSelectionId = '',
                                                      actionList = [],
                                                      onAction,
                                                      badgeColor = 'primary',
                                                      elevation = 4
}) => {
  const [selectedButtonId, setSelectedButtonId] = useState<string>(defaultSelectionId);
  const classes = useStyles();

  const handleValueChanged = (buttonId: string): void => {
    setSelectedButtonId(buttonId);
    onAction && onAction(buttonId);
  };

  return (
    <Paper elevation={elevation}>
      <BottomNavigation value={selectedButtonId} color="textPrimary"
                        onChange={(_, val) => handleValueChanged(val)}
                        showLabels={showLabels}>
        {
          actionList.map(b =>
            <BottomNavigationAction className={classes.BottomNavigationAction}
                                    key={b.id}
                                    label={b.label}
                                    value={b.id}
                                    disabled={disabled || b.disabled}
                                    icon={
                                      <Badge className={classes.Badge}
                                             color={badgeColor}
                                             variant="standard"
                                             invisible={!b.badgeContent}
                                             badgeContent={b.badgeContent}>
                                        {b.icon}
                                      </Badge>
                                    }
            />
          )
        }
      </BottomNavigation>
    </Paper>
  );
};

export default AppFooterBar;