import {SubModelBuilder} from './SubModelBuilder';
import {InlineListBuilder} from './InlineListBuilder';
import {MetaFieldBuilder} from './MetaFieldBuilder';
import {Inline} from '../../../declarations/meta/Inline';

export class InlineBuilder extends SubModelBuilder<MetaFieldBuilder, Inline> {

  constructor(parentBuilder: MetaFieldBuilder, model: Inline['model'], onBuild: (buildResult: Inline) => void) {
    super(parentBuilder, {model}, onBuild);
  }

  public addObjectName(value: boolean = true): InlineBuilder {
    this.product.add_object_name = value;
    return this;
  }

  public checkExpired(value: boolean = true): InlineBuilder {
    this.product.check_expired = value;
    return this;
  }

  public withDateType(dateType: Inline['date_type'] = 'historic'): InlineBuilder {
    this.product.date_type = dateType;
    return this;
  }

  public isToDateField(value: boolean = true): InlineBuilder {
    this.product.is_to_date_field = value;
    return this;
  }

  public noIndexNesting(value: boolean = true): InlineBuilder {
    this.product.no_index_nesting = value;
    return this;
  }

  public withProp(prop: string): InlineBuilder {
    this.product.prop = prop;
    return this;
  }

  public withRequiredField(fieldName: string): InlineBuilder {
    this.product.required_field = fieldName;
    return this;
  }

  public withToDateField(fieldName: string): InlineBuilder {
    this.product.to_date_field = fieldName;
    return this;
  }

  public todayDate(value: boolean = true): InlineBuilder {
    this.product.today_date = value;
    return this;
  }

  public withUniqueProp(fieldName: string): InlineBuilder {
    this.product.unique_prop = fieldName;
    return this;
  }

  public withView(view: Inline['view'] = 'vertical'): InlineBuilder {
    this.product.view = view;
    return this;
  }

  public withInlineList(): InlineListBuilder {
    return new InlineListBuilder(this, list => this.product.inline_list = list);
  }

}