import {SubModelBuilder} from './SubModelBuilder';
import {ParamsBuilder} from './ParamsBuilder';
import {KulturnavParamsBuilder} from './KulturnavParamsBuilder';
import {SelectorBuilder} from './SelectorBuilder';
import {ReferenceFilterBuilder} from './ReferenceFilterBuilder';
import {MetaFieldBuilder} from './MetaFieldBuilder';
import {Selector} from '../../../declarations/meta/Selector';
import {Reference} from '../../../declarations/meta/Reference';

export class ReferenceBuilder extends SubModelBuilder<MetaFieldBuilder, Reference> {

  constructor(parentBuilder: MetaFieldBuilder, onBuild: (buildResult: Reference) => void) {
    super(parentBuilder, {
      set_others: []
    }, onBuild);
  }

  public canAddNew(value: boolean = true): ReferenceBuilder {
    this.product.can_add_new = value;
    return this;
  }

  public canJoin(value: boolean = true): ReferenceBuilder {
    this.product.can_join = value;
    return this;
  }

  public isHierarchic(value: boolean = true): ReferenceBuilder {
    this.product.is_hierarchic = value;
    return this;
  }

  public noClear(value: boolean = true): ReferenceBuilder {
    this.product.no_clear = value;
    return this;
  }

  public searchKulturnav(value: boolean = true): ReferenceBuilder {
    this.product.search_kulturnav = value;
    return this;
  }

  public withCompareValueField(fieldName: string): ReferenceBuilder {
    this.product.compare_value_field = fieldName;
    return this;
  }

  public withFilterOperator(operator: Reference['filter_operator'] = 'AND'): ReferenceBuilder {
    this.product.filter_operator = operator;
    return this;
  }

  public withLabelProp(propName: string): ReferenceBuilder {
    this.product.label_prop = propName;
    return this;
  }

  public withMetaType(metaType: string = 'artifact'): ReferenceBuilder {
    this.product.meta_type = metaType;
    return this;
  }

  public withObjectType(objectType: string): ReferenceBuilder {
    this.product.object_type = objectType;
    return this;
  }

  public withParentField(parentFieldName: string): ReferenceBuilder {
    this.product.parent_field = parentFieldName;
    return this;
  }

  public withParentTargetField(parentTargetFieldName: string): ReferenceBuilder {
    this.product.parent_target_field = parentTargetFieldName;
    return this;
  }

  public withReferenceProp(propName: string): ReferenceBuilder {
    this.product.ref_prop = propName;
    return this;
  }

  public withSearchDisplayLimit(limit: number = 100): ReferenceBuilder {
    this.product.search_display_limit = limit;
    return this;
  }

  public withSort(sortField: string): ReferenceBuilder {
    this.product.sort = sortField;
    return this;
  }

  public withTitle(title: string): ReferenceBuilder {
    this.product.title = title;
    return this;
  }

  public withSetOthersField(sourceField: string, targetField: string): ReferenceBuilder {
    this.product.set_others!.push({source_field: sourceField, target_field: targetField});
    return this;
  }

  public withNewParams(buttonTitle: string, newObjectType: string): ParamsBuilder {
    return new ParamsBuilder(this, buttonTitle, newObjectType, p => this.product.add_new_params = p);
  }

  public withKulturnavParams(entityType: string): KulturnavParamsBuilder {
    return new KulturnavParamsBuilder(this, entityType, knp => this.product.kulturnav_params = knp);
  }

  public withSelector(type: Selector['selector_type'], view: string, buttonLabel: string, hideButton: boolean = false): SelectorBuilder {
    return new SelectorBuilder(this, type, view, buttonLabel, hideButton, selector => this.product.selector = selector);
  }

  public withReferenceFilter(): ReferenceFilterBuilder {
    return new ReferenceFilterBuilder(this, refFilter => {
      if (!this.product.ref_filter) {
        this.product.ref_filter = refFilter;
      } else if (!Array.isArray(this.product.ref_filter)) {
        this.product.ref_filter = [this.product.ref_filter, refFilter];
      } else {
        this.product.ref_filter.push(refFilter);
      }
    });
  }

}