import React, {FC, useEffect, useState} from 'react';
import PrimusEkulturLoginContainer from './PrimusEkulturLoginContainer';
import PrimusEkulturLoginOffline from './PrimusEkulturLoginOffline';
import PrimusEkulturLoading from './PrimusEkulturLoading';
import {usePrimusApi} from '../../hooks/PrimusApiProvider';
import {useOfflineEvent} from '../../hooks/UseOfflineEvent';
import {useLoginLabels} from './PrimusEkulturLabelProvider';

export interface PrimusEkulturLogoutProps {
  onLogout?: () => void;
}

export const PrimusEkulturLogout: FC<PrimusEkulturLogoutProps> = ({onLogout}) => {

  const isOffline = useOfflineEvent();
  const [logoutFailed, setLogoutFailed] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const labels = useLoginLabels();
  const api = usePrimusApi()!;

  useEffect(() => {
    if (!isOffline && !loading && !logoutFailed) {
      setLoading(true);
      api.logout()
        .then(() => {
          onLogout && onLogout();
          window.location.assign(window.location.origin)
        })
        .catch(err => {
          console.error(err);
          setLogoutFailed(true)
        })
        .finally(() => setLoading(false));
    }
  }, [onLogout, api, isOffline, loading, logoutFailed]);

  if (isOffline) {
    return <PrimusEkulturLoginOffline/>;
  }

  return (
      <PrimusEkulturLoginContainer>
          <PrimusEkulturLoading loading={loading}
                                text={logoutFailed ? labels.logoutFailed : labels.loggingOut}
                                textColor={logoutFailed ? 'error' : 'initial'}/>
      </PrimusEkulturLoginContainer>
  );
};

export default PrimusEkulturLogout;