import React, {ElementType, FC} from 'react';
import {styled} from '@material-ui/core';

type FlexAlignment = 'flex-start' | 'center' | 'flex-end' | 'space-between' | 'space-evenly' | 'unset' | 'initial' | 'auto' | 'inherit';
type CssOverflow = 'visible' | 'hidden' | 'scroll' | 'auto' | 'initial' | 'inherit';

export interface FlexContainerProps {
  vertical?: boolean;
  justify?: FlexAlignment;
  align?: FlexAlignment;
  fullWidth?: boolean;
  fullHeight?: boolean;
  margin?: Array<number>;
  padding?: Array<number>;
  overflow?: CssOverflow;
}

interface FlexContainerComponentProps extends FlexContainerProps {
  className?: string;
}

const Container: FC<FlexContainerComponentProps> = ({children, className}) => (
  <div className={className}>{children}</div>
)

export const FlexContainer: ElementType<FlexContainerProps> = styled(Container)(props => ({
  display: 'flex',
  flexFlow: props.vertical !== false ? 'column' : 'row nowrap',
  justifyContent: props.justify || 'center',
  alignItems: props.align || 'center',
  width: props.fullWidth !== false ? '100%' : 'initial',
  height: props.fullHeight !== false ? '100%' : 'initial',
  // @ts-ignore
  margin: !!props.margin ? props.theme.spacing(...props.margin) : 'initial',
  // @ts-ignore
  padding: !!props.padding ? props.theme.spacing(...props.padding) : 'initial',
  overflow: props.overflow || 'visible',
}));

export default FlexContainer;