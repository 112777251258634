import {SubModelBuilder} from './SubModelBuilder';
import {BaseBuilder} from './BaseBuilder';
import {FieldIfCondition} from '../../../declarations/meta/FieldIfCondition';
import {FieldIf} from '../../../declarations/meta/FieldIf';

export class FieldIfBuilder<T extends BaseBuilder<any>> extends SubModelBuilder<T, FieldIf> {

  constructor(parentBuilder: T, ifType: FieldIf['if_type'], operator: FieldIf['operator'], onBuild: (buildResult: FieldIf) => void) {
    super(parentBuilder, {if_type: ifType, operator, conditions: []}, onBuild);
  }

  public withCondition(condition: FieldIfCondition): FieldIfBuilder<T> {
    this.product.conditions.push(condition);
    return this;
  }

}