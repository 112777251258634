import {ModelHelpers} from './ModelHelpers';
import {FieldIfEvaluation, evaluatePrimusModelIfs} from './FieldIfEvaluator';
import getFieldTypeFromInputType from './InputTypeToFieldTypeMap';
import {Meta} from '../../declarations/Meta';
import {PrimusApi} from '../../services/PrimusApi';
import {ModelsResponse} from '../../declarations/ModelsResponse';
import {PrimusTranslation} from '../../declarations/PrimusTranslation';
import {MetaField} from '../../declarations/meta/MetaField';
import {KeyValue} from '../../declarations/KeyValue';
import {Model} from '../../declarations/Model';
import {JsonSchemaForm} from '../../declarations/JsonSchemaForm';
import {InputType} from '../../declarations/InputType';
import {ObjectHelpers} from './ObjectHelpers';
import {CustomFieldTypes} from './JsonSchemaFieldType';
import {PrimusLocale} from '../../declarations/PrimusLocale';
import {Artifact} from '../../declarations/Artifact';


export class TransformerError extends Error {

  constructor(message: string) {
    super(message);
  }

}
const OPTIONS_TO_LOAD = 100;

const getOrder = (f?: FieldIfEvaluation): number => Number((f?.field?.order || f?.field?.sub_order) ?? 0);
const asc = (a?: FieldIfEvaluation, b?: FieldIfEvaluation): number => getOrder(a) - getOrder(b);

export class PrimusModelToJsonSchemaTransformer {

  private readonly api: PrimusApi;
  private readonly models: ModelsResponse;
  private readonly translations: PrimusTranslation;
  private readonly fieldMetas: { [fieldName: string]: FieldIfEvaluation };

  private constructor(api: PrimusApi, models: ModelsResponse, translations: PrimusTranslation) {
    this.fieldMetas = {} as Meta;
    this.api = api;
    this.models  = models;
    this.translations = translations;
  }

  /**
   * Add the meta for this field to the fieldMetas,
   * so that the metas will be available in the form-context
   * @param {FieldIfEvaluation} field
   * @private
   */
  private addFieldToFieldMetas(field: FieldIfEvaluation): void {
    const prop: string | undefined = field?.field?.name;
    if (!!prop && !this.fieldMetas[prop]) {
      this.fieldMetas[prop] = field;
    }
  }

  /**
   *
   * @param {string} key
   * @return {string}
   * @private
   */
  private translate(key: string): string {
    return this.translations[key] || key;
  }

  /**
   *
   * @param {MetaField} field
   * @return {Promise<Array<KeyValue<string>>>}
   * @private
   */
  private async getOptionsForField(field: MetaField): Promise<Array<KeyValue<string>>> {
    if (!field) {
      return [];
    }
    const params = ModelHelpers.getSearchParamsForReference(field);
    try {
      params.start = 0;
      params.rows = OPTIONS_TO_LOAD;
      const options = await this.api.search(params);
      const artifacts: Array<Artifact> = options?.artifacts || [];

      if (options?.search_count > OPTIONS_TO_LOAD) {
        params.start = OPTIONS_TO_LOAD;
        params.rows = options.search_count - OPTIONS_TO_LOAD;
        const rest = await this.api.search(params);
        if (rest?.artifacts) {
          artifacts.push(...rest?.artifacts);
        }
      }
      
      return artifacts.map(opt => ({
        key: opt.artifact_id,
        value: opt.artifact_name
      }));
    } catch (e) {
      console.error('[TRANSFORMER] Unable to load options: ', e);
      return [];
    }
  }

  /**
   *
   * @param {string} title
   * @return {JsonSchemaForm}
   * @private
   */
  private static getPrecisionDateSchema(title: string = ''): JsonSchemaForm {
    return {
      schema: {
        type: 'object',
        title,
        properties: {
          dd_date: { type: 'number' },
          dd_precision: { type: 'string' }
        },
        default: {}
      },
      uiSchema: {
        'ui:field': 'precisionDate',
        'ui:emptyValue': {}
      }
    } as JsonSchemaForm;
  }

  /**
   *
   * @param {FieldIfEvaluation} field
   * @return {JsonSchemaForm | null}
   * @private
   */
  private async getSchemaForInlineField(field: FieldIfEvaluation): Promise<JsonSchemaForm | null> {
    const inlineModelName = field?.field?.inline?.model;
    const prop = field?.field?.name;

    if (!inlineModelName) {
      return null;
    }

    if (!this.models.hasOwnProperty(inlineModelName)) {
      throw new TransformerError(`[TRANSFORMER] Could not find the model '${inlineModelName}' to use for field '${prop}'`);
    }

    const model = this.models[inlineModelName];

    if (model) {
      const schema = await this.getSchemaForModel(model, model.$$meta);
      if (!schema) {
        throw new TransformerError(`[TRANSFORMER] Generated empty form for model '${inlineModelName}' in field '${prop}'. Ignoring field.`);
      }
      return schema;
    } else {
      throw new TransformerError(`[TRANSFORMER] Could not find name of the model to use for field '${prop}'`);
    }
  }

  /**
   *
   * @param {JsonSchemaForm} schema
   * @param value
   * @param toItems
   * @private
   */
  private static setDefaultValue(schema: JsonSchemaForm, value?: any, toItems: boolean = false): void {
    if (toItems) {
      if (typeof schema.schema.items === 'object' && !Array.isArray(schema.schema.items)) {
        schema.schema.items.default = value;
        if (!schema.uiSchema['items']) {
          schema.uiSchema['items'] = {};
        }
        schema.uiSchema['items']['ui:emptyValue'] = value;
      }
    } else {
      schema.schema.default = value;
      schema.uiSchema['ui:emptyValue'] = value;
    }
  }

  /**
   *
   * @param {JsonSchemaForm["schema"]} schema
   * @param {Array<KeyValue<string>>} options
   * @param required
   * @param uniqueItems
   * @private
   */
  private static addOptionsToSchema(schema: JsonSchemaForm['schema'], options: Array<KeyValue<string>> = [], required: boolean = false, uniqueItems: boolean = false): void {
    // const type = schema.type;
    schema.enum = options.map(opt => opt.key);
    schema.enumNames = options.map(opt => opt.value);

    schema.uniqueItems = uniqueItems;

    if (!required) {
      schema.enum.unshift('');
      schema.enumNames.unshift('');
    }
  }

  /**
   *
   * @param {FieldIfEvaluation} field
   * @param {JsonSchemaForm["schema"]} schema
   * @return {Promise<void>}
   * @private
   */
  private async loadOptionsFromReference(field: FieldIfEvaluation, schema: JsonSchemaForm): Promise<void> {
    if (field?.field?.reference && !!schema.schema) {
      if (!schema.schema.enum) {
        const options = await this.getOptionsForField(field.field);
        if (!!options?.length) {
          PrimusModelToJsonSchemaTransformer.addOptionsToSchema(schema.schema, options, field.require);
        }
      } else {
        console.warn(`[TRANSFORMER] Ignoring reference. Options already loaded for field '${field?.field?.name}'`);
      }
    }
  }

  /**
   *
   * @param {FieldIfEvaluation} field
   * @param {JsonSchemaForm["schema"]} schema
   * @private
   */
  private static applyFieldValidationRulesToSchema(field: FieldIfEvaluation, schema: JsonSchemaForm['schema']): void {
    const validation = field?.field?.validation;
    if (validation) {
      const type = getFieldTypeFromInputType(field.field.input_type);
      // TODO: Validate compare
      // TODO: Validate username
      // TODO: Validate pattern
      // TODO: Handle min/max-validation when input type is 'precision-date'
      if (validation.min_length !== undefined) {
        const min = validation.min_length;
        if (type === 'number') {
          schema['minimum'] = min;
        } else {
          schema['minLength'] = min < 0 ? 0 : min;
        }
      }

      if (validation.max_length !== undefined) {
        const max = validation.max_length;
        if (type === 'number') {
          schema['maximum'] = max;
        } else if (max > 0) {
          schema['maxLength'] = max;
        } else {
          console.error(`[TRANSFORMER][VALIDATION] Invalid max/max-length '${max}'. Ignoring validation rule.`);
        }
      }

    }
  }

  /**
   *
   * @param {Model} model
   * @param {Meta} meta
   * @return {Promise<JsonSchemaForm | null>}
   * @private
   */
  private async getSchemaForModel(model: Model, meta?: Meta): Promise<JsonSchemaForm | null> {
    // Create copy and get all fields that should be edited
    const {modelCpy, metaCpy} = this.copyModelAndMeta(model, meta);
    const fields = evaluatePrimusModelIfs(modelCpy, metaCpy).filter(field => field?.field && field.display && field.edit);

    if (!fields?.length) {
      return null;
    }

    // Start building the object-schema

    const rootSchema: JsonSchemaForm = {
      schema: {
        type: 'object',
        title: '',
        properties: {},
        required: [],
        default: {}
      },
      uiSchema: {
        'ui:emptyValue': {},
        'ui:disabled': false,
        'ui:order': fields.sort(asc).map(f => f.field.name)
      }
    };

    // Create schemas for all object-props:

    await Promise.all(
      fields.map(async field => {
        const prop = field.field.name;

        this.addFieldToFieldMetas(field);

        // Get type and create field-schemas
        const inputType = field.field.input_type;
        const type = getFieldTypeFromInputType(inputType);

        let inlineSchemas: JsonSchemaForm | null;
        let fieldSchemas: JsonSchemaForm = {
          schema: {
            type,
            title: this.translate(field.field.field_title || field.field.admin_title || '')
          },
          uiSchema: {
            'ui:disabled': field.disable
          }
        };


        if (inputType === InputType.PRECISION_DATE) {
          inlineSchemas = PrimusModelToJsonSchemaTransformer.getPrecisionDateSchema(fieldSchemas.schema.title);
        } else {
          try {
            inlineSchemas = await this.getSchemaForInlineField(field);
          } catch (e) {
            inlineSchemas = null;
            if (e instanceof TransformerError) {
              console.warn(e.message);
              return;
            }
          }
        }

        // Set field-specific data
        let loadFieldReferenceOptions: boolean = true;

        if (type === 'object') {
          fieldSchemas = inlineSchemas!;
          PrimusModelToJsonSchemaTransformer.setDefaultValue(fieldSchemas, {});
        } else {

          if (type === 'array'){
            fieldSchemas.schema.items = inlineSchemas?.schema || {} as JsonSchemaForm['schema'];
            fieldSchemas.uiSchema.items = inlineSchemas?.uiSchema || {} as JsonSchemaForm['uiSchema'];
            fieldSchemas.uiSchema['ui:options'] = {
              orderable: true,
              addable: true,
              removable: true
            };
            PrimusModelToJsonSchemaTransformer.setDefaultValue(fieldSchemas, []);
          } else if (type !== 'number') {
            PrimusModelToJsonSchemaTransformer.setDefaultValue(fieldSchemas, '');
          }

          // Handle field specifics
          switch (inputType) {
            case InputType.CHECK_ARRAY:
              const options = field.field.option_info?.options;

              if (!options?.length) {
                return;
              }

              const items = options.map(opt => ({
                key: opt.value,
                value: this.translate(opt.label)
              } as KeyValue<string>));

              fieldSchemas.schema.items = {type: 'string'};
              PrimusModelToJsonSchemaTransformer.addOptionsToSchema(fieldSchemas.schema.items, items, field.require, true);
              PrimusModelToJsonSchemaTransformer.setDefaultValue(fieldSchemas, '', true);
              fieldSchemas.uiSchema['ui:widget'] = 'checkboxes';
              break;

            case InputType.INLINE:
              if (field.field.inline?.required_field) {
                rootSchema.schema.required!.push(field.field.inline?.required_field);
              }
              break;

            case InputType.OBJECT_REFERENCE:
            case InputType.INLINE_ARRAY:
              if(!inlineSchemas) {
                return;
              }
              break;

            case InputType.REF_ARRAY:
            case InputType.REF_DYNAMIC_ARRAY:
              // Avoid loading options, as these are loaded in the inlineModelSchema
              loadFieldReferenceOptions = false;
              break;

            case InputType.TEXT_AREA:
              const existingWidget = fieldSchemas.uiSchema['ui:widget'];
              if (!!existingWidget) {
                console.warn('[TRANSFORMER] Overriding widget to textarea', existingWidget);
              }
              fieldSchemas.uiSchema['ui:widget'] = 'textarea';
              break;

            case InputType.IMAGE:
            case InputType.ACTION_BUTTON:
            case InputType.COMPARE_VALUE:
              console.warn(`Input type '${inputType}' not supported`);
              fieldSchemas.uiSchema['ui:widget'] = 'hidden';
              break;
          }
        }

        // Set custom fields
        let customField: CustomFieldTypes | null = null;

        switch (inputType) {
          case InputType.SEARCH_SELECTOR:
            customField = 'searchSelector';
            break;
          case InputType.SEARCH_SELECTOR_MULTIPLE:
            customField = 'searchSelectorMultiple';
            break;
          case InputType.IDENTIFIER:
            customField = 'identifier';
            break;
        }

        if (customField) {
          fieldSchemas.uiSchema['ui:field'] = customField;
        }

        // Load reference data
        if (loadFieldReferenceOptions) {
          await this.loadOptionsFromReference(field, fieldSchemas);
        }

        // Set validation
        PrimusModelToJsonSchemaTransformer.applyFieldValidationRulesToSchema(field, fieldSchemas.schema);

        // Mark the prop as required in the parent object

        if (field.require) {
          rootSchema.schema['required']!.push(prop);
        }

        // Add schema and UISchema to parent schemas
        rootSchema.schema['properties']![prop] = fieldSchemas.schema;
        rootSchema.uiSchema[prop] = fieldSchemas.uiSchema;
      })
    );

    // Return the generated schema
    return rootSchema;
  }


  /**
   *
   * @param {Model} model
   * @param {Meta} meta
   * @return {{modelCpy: Model, metaCpy: Meta}}
   * @private
   */
  private copyModelAndMeta(model: Model, meta?: Meta): {modelCpy: Model, metaCpy: Meta} {
    if (!model || (!meta && !model.$$meta)) {
      throw new TransformerError('Missing model or metadata');
    }
    return {
      modelCpy: Object.keys(model)
        .filter(key => !key.startsWith('$'))
        .reduce((model, key) => ({
          ...model,
          [key]: model === null ? undefined : ObjectHelpers.deepCopy(model[key])
        }), {} as Model),

      metaCpy: ObjectHelpers.deepCopy(meta || model.$$meta)!
    };
  }


  /**
   *
   * @param {Model} model
   * @param {Meta} meta
   * @return {Promise<JsonSchemaForm>}
   */
  public async transform(model: Model, meta?: Meta): Promise<JsonSchemaForm> {
    const schema = await this.getSchemaForModel(model, meta);
    return schema || {schema: {}, uiSchema: {}} as JsonSchemaForm;
  }

  /**
   *
   * @return {PrimusModelToJsonSchemaTransformer["fieldMetas"]}
   */
  public getFieldMetas(): PrimusModelToJsonSchemaTransformer['fieldMetas'] {
    return this.fieldMetas;
  }

  /**
   *
   * @param {PrimusApi} api
   * @param locale
   * @return {Promise<PrimusModelToJsonSchemaTransformer>}
   */
  public static async createInstance(api: PrimusApi, locale?: PrimusLocale): Promise<PrimusModelToJsonSchemaTransformer> {
    const models = await api.getModels();
    if (!models) {
      throw new TransformerError('Unable to load models');
    }
    const trans = await api.getTranslations(locale);
    if (!trans) {
      throw new TransformerError('Unable to load translations');
    }
    return new PrimusModelToJsonSchemaTransformer(api, models, trans);
  }
}