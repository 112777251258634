export type AccessorFn<T, U> = (item: T) => U;
export type Predicate<T> = (item: T) => boolean;

export type Group<T, K> = {key: K, group: Array<T>};

/**
 * A class providing static methods for array-modification.
 */
export abstract class ArrayHelpers {

  /**
   * Sums an array of numbers.
   * And, if necessary, maps an array of objects to an array of numbers by the provided prop-param.
   * @param {Array<T>} items The items to sum
   * @param compareValueFn A function that returns the value to sum
   * @param {number} startVal The initial value to start adding to
   * @return {number} the sum of all numbers
   */
  public static sum<T>(items: Array<T>, compareValueFn: AccessorFn<T, number>, startVal: number = 0): number {
    return items.reduce((acc, val) => acc + compareValueFn(val), startVal);
  }

  /**
   * Removes duplicate items from an array.
   * @param {Array<T>} items The items to remove duplicates from
   * @param {AccessorFn<T, U>} comparator A function used to fetch the values to compare.
   * @return {Array<T>} A new array containing only distinct elements
   */
  public static distinct<T, U = keyof T>(items: Array<T>, comparator: AccessorFn<T, U>): Array<T> {
    return items.reduce((acc: Array<T>, val: T) => (
        acc.some(v => comparator(v) === comparator(val)) ? acc : [...acc, val]
      ),
      [] as Array<T>
    );
  }

  /**
   * Groups an array of objects by one of the properties in those objects
   * @template {T} The item type
   * @template {U} The type of the value used to compare the keys
   * @template {K} The type of the Key
   * @param {Array<T>} items The items to group
   * @param {keyof T} key The key to group by
   * @param {AccessorFn<K, U>} comparator A function determining how the key should be compared
   * @return {Array<{key: K, group: Array<T>}>} An array of groups
   */
  public static groupBy<T, U, K = T[keyof T]>(items: Array<T>, key: keyof T, comparator: AccessorFn<K, U>): Array<Group<T, K>> {
    const keys: Array<K> = ArrayHelpers.distinct<K, U>(items.map(item => item[key] as any as K), comparator);
    return keys.map(k => ({
      key: k,
      group: items.filter(item => comparator(item[key] as any as K) === comparator(k))
    }));
  }

  /**
   * Flattens an array of arrays to a single array of items
   * @param {Array<T>} items The array to flatten
   * @param {AccessorFn<T, Array<U>>} subArrayAccessor The function to get/access the array to flatten
   * @return {Array<U>} An array containing all elements in the subarrays
   */
  public static flatten<T, U = T>(items: Array<T>, subArrayAccessor: AccessorFn<T, Array<U>>): Array<U> {
    return items.map(subArrayAccessor).reduce((acc, val) => [...acc, ...val], []);
  }

  /**
   * Checks whether or not a predicate is true for all elements in an array
   * @param {Array<T>} items The items to test
   * @param {Predicate<T>} predicate The predicate to check
   * @return {boolean} True if the predicate is true for all items
   */
  public static all<T>(items: Array<T>, predicate: Predicate<T>): boolean {
    return !items.some(i => !predicate(i));
  }

}