import {BaseBuilder} from './BaseBuilder';

export abstract class SubModelBuilder<P extends BaseBuilder<any>, T> extends BaseBuilder<T> {

  protected readonly parentBuilder: P;

  protected constructor(parentBuilder: P,
                        product: T,
                        onBuild?: (buildResult: T) => void) {
    super(product, onBuild);
    this.parentBuilder = parentBuilder;
  }

  /**
   * Completes the current product and returns the
   * builder that instantiated this builder.
   * @return {P}
   */
  public complete(): P {
    this.build();
    return this.parentBuilder;
  }
}