import {SubModelBuilder} from './SubModelBuilder';
import {AgentSubTypeBuilder} from './AgentSubTypeBuilder';
import {ReferenceBuilder} from './ReferenceBuilder';
import {KulturnavParams} from '../../../declarations/meta/KulturnavParams';

export class KulturnavParamsBuilder extends SubModelBuilder<ReferenceBuilder, KulturnavParams> {

  constructor(parentBuilder: ReferenceBuilder, entityType: string, onBuild: (buildResult: KulturnavParams) => void) {
    super(parentBuilder, {entity_type: entityType}, onBuild);
  }

  public withConceptTypeId(conceptTypeId: string): KulturnavParamsBuilder {
    this.product.concept_type_id = conceptTypeId;
    return this;
  }

  public withAgentSubType(paramType: string): AgentSubTypeBuilder {
    return new AgentSubTypeBuilder(this, paramType, a => this.product.agent_sub_type = a);
  }

}