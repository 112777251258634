import React, {FC} from 'react';
import {createStyles, styled, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import FlexContainer from '../../components/FlexContainer';
import {PRIMUS_PRIMARY_DARK_COLOR} from '../../asset/colors';
import PrimusLogo from '../../components/PrimusLogo';
import {useLoginLabels} from './PrimusEkulturLabelProvider';

const useStyles = makeStyles(() => createStyles({
  logoText: {
    fontSize: '3.5rem',
  }
}));

const Container = styled(FlexContainer)(({theme}) => ({
  backgroundColor: PRIMUS_PRIMARY_DARK_COLOR,
  color: theme.palette.getContrastText(PRIMUS_PRIMARY_DARK_COLOR),
}));

export const PrimusEkulturLoginOffline: FC = () => {
  const classes = useStyles();
  const labels = useLoginLabels();

  return (
    <Container>
      <FlexContainer vertical={false} fullHeight={false}>
        <PrimusLogo height="200px"/>
        <Typography variant="h4" component="span" className={classes.logoText}>
          {labels.appTitle}
        </Typography>
      </FlexContainer>
      <Typography variant="overline" color="error">
        {labels.appOffline}
      </Typography>
    </Container>
  );
};

export default PrimusEkulturLoginOffline;