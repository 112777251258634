import {login, logout, refreshToken, codeToToken } from '@kulturit/authentication';
import {HttpService} from './HttpService';
import {LoginResponse} from '../declarations/LoginResponse';
import {PrimusServer} from '../declarations/PrimusServer';
import {EkulturUser} from '../declarations/EKulturModels/EkulturUser';
import {Museum, MuseumsResponse} from '../declarations/EKulturModels/MuseumsResponse';
import {ApplicationsResponse} from '../declarations/EKulturModels/ApplicationsResponse';
import {MuseumUrlsResponse} from '../declarations/EKulturModels/MuseumUrlsResponse';

/**
 * Provides functions needed to authenticate a user from eKultur in Primus
 */
export abstract class PrimusAuthService extends HttpService {

  private readonly gatewayUri: string;
  private readonly loginHost: string;
  private readonly primusAppId: string;
  private _isAuthenticated: boolean;

  protected constructor(gatewayUri: string, loginHost: string, primusAppId: string) {
    super();
    this._isAuthenticated = !!PrimusAuthService.getToken();
    if (!gatewayUri) {
      throw Error('Missing gatewayUri');
    }
    if (!loginHost) {
      throw Error('Missing loginHost');
    }
    if (!primusAppId) {
      throw Error('Missing primusAppId');
    }
    this.gatewayUri = gatewayUri;
    this.loginHost = loginHost;
    this.primusAppId = primusAppId;
  }


  private static getToken(): string | null {
    return window.localStorage.getItem('kit.access_token');
  }

  private static getAuthHeader(): string {
    const token = PrimusAuthService.getToken();
    return token ? `Bearer ${token}` : '';
  }


  private async logoutIDP(): Promise<void> {
    return new Promise(resolve => logout({
      signal: null,
      onLogout: resolve,
      apiGateway: this.gatewayUri
    }));
  }

  private async logoutPrimus(): Promise<void> {
    const res = await this.get('/logout');
    if (!res?.ok) {
      throw Error('Unable to log out');
    }
  }

  private async getApplicationsAvailableToUser(userId: string): Promise<ApplicationsResponse> {
    return this.getFromEkultur(`/authz/users/${userId}/applications/`);
  }

  private async getMuseums(fields?: Array<string>, identifier?: string | Array<string>, publishToEuropeana?: boolean): Promise<MuseumsResponse | Museum> {
    const params: any = {};
    if (identifier) {
      if (typeof identifier === 'string') {
        params['identifier'] = identifier;
      } else if (Array.isArray(identifier)) {
        params['identifier[]'] = identifier.join(',');
      }
    }
    if (publishToEuropeana !== undefined) {
      params['publish_to_europeana'] = publishToEuropeana;
    }
    if (fields) {
      params['fields[]'] = fields.join(',');
    }
    return this.getFromEkultur('/museum-api/museums', params);
  }

  private async getMuseumUrls(appId: string, museumId: number, url?: string): Promise<Array<MuseumUrlsResponse>> {
    return this.getFromEkultur('/app-registry/urls/', {appId, museumId: String(museumId), url: url});
  }

  private async getFromEkultur<T>(path: string, params?: {[name: string]: string | undefined}): Promise<T> {
    let search = '';
    if (params) {
      search = '?' + Object.keys(params)
        .filter(name => params[name] !== undefined)
        .map(name => `${name}=${params[name]}`)
        .join('&');
    }
    const response: Response = await fetch(`${this.gatewayUri}${path}${search}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': PrimusAuthService.getAuthHeader(),
      }
    });
    return response.json();
  }

  protected getDefaultConfig(): Request {
    return this.combineConfigs(
      super.getDefaultConfig(),
      {
        headers: new Headers({
          'Authorization': PrimusAuthService.getAuthHeader()
        }),
        credentials: 'include'
      }
    );
  }


  protected setAuthenticated(value: boolean) {
    this._isAuthenticated = value;
  }

  /**
   * Attempt to refresh the token.
   * @return {Promise<boolean>} True if the token is refreshed, false otherwise
   */
  public async refreshToken(): Promise<boolean> {
    const auth = await new Promise<boolean>(resolve => {
      try {
        refreshToken({
          apiGateway: this.gatewayUri,
          onSuccess: () => resolve(true),
          onError: () => resolve(false)
        });
      } catch (e) {
        resolve(false);
      }
    });
    this.setAuthenticated(auth);
    return auth;
  }

  /**
   *
   * @param {string} server
   * @return {Promise<LoginResponse>}
   */
  public async loginPrimus(server: string): Promise<LoginResponse> {
    const res = await this.get('/login', {
      headers: new Headers({
        'Authorization': PrimusAuthService.getAuthHeader()
      }),
      url: `${server}${this.getPathPrefix()}`
    } as Request);

    this.setAuthenticated(res?.ok);

    if (res?.ok) {
      this.setPrimusInstance(server);
    }

    return res.json();
  }

  /**
   *
   * @param {string} callbackUrl The URL that requests the token
   * @param {string} redirectTo The URL to redirect to when the login is complete
   */
  public loginIDP(callbackUrl: string, redirectTo: string): void {
    login({
      locationPostLogin: redirectTo,
      redirectUri: callbackUrl,
      loginHost: this.loginHost
    });
  }

  /**
   *
   * @return {Promise<boolean>}
   */
  public async requestTokenFromCode(code: string, state: string, callbackUrl: string,): Promise<boolean> {
    return new Promise(resolve => {
      try {
        codeToToken({
          code,
          state,
          redirectUri: callbackUrl,
          apiGateway: this.gatewayUri,
          onSuccess: () => resolve(true),
          onError: () => resolve(false)
        });
      } catch (e) {
        console.error(e);
        resolve(false);
      }
    });
  }


  public async getLoggedInUserInfo(): Promise<EkulturUser> {
    const response = await fetch(`${this.gatewayUri}/auths/oauth2/userInfo`, {
      headers: {
        'Authorization': PrimusAuthService.getAuthHeader()
      },
      credentials: 'include'
    });
    return response?.ok ? response.json() : response.text();
  }

  public async getAvailablePrimusInstances(userId: string): Promise<Array<PrimusServer>> {
    const applications = await this.getApplicationsAvailableToUser(userId);
    const availableMuseumIds = (applications?.museums || []).filter(museum =>
      museum.applications.some(app => app.id === this.primusAppId)
    ).map(museum => museum.id);

    const museumsResponse = await this.getMuseums(['id', 'name']) as MuseumsResponse;
    const museums = museumsResponse?.museums || [];
    const availableMuseums = museums.filter(museum => availableMuseumIds.includes(museum.id));

    const urlResponses: Array<Array<MuseumUrlsResponse>> = await Promise.all(
      availableMuseums.map(museum => this.getMuseumUrls(this.primusAppId, museum.id))
    );
    const urls = urlResponses.reduce((acc, val) => [...acc, ...val], []);
    return availableMuseums.map(museum => ({
      id: museum.id,
      name: museum.name,
      serverUrl: urls.find(url => url.museumId === museum.id)?.url
    } as PrimusServer));
  }

  public async logout(): Promise<void> {
    await this.logoutIDP();
    await this.logoutPrimus();
    this.setPrimusInstance(null);
    this.setAuthenticated(false);
  }

  public isAuthenticated(): boolean {
    return this._isAuthenticated;
  }

  public getServer(): string {
    return this.getBaseUrl();
  }

  protected abstract setPrimusInstance(serverUrl: string | null): void;

}