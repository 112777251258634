import {SubModelBuilder} from './SubModelBuilder';
import {OptionBuilder} from './OptionBuilder';
import {MetaFieldBuilder} from './MetaFieldBuilder';
import {Option} from '../../../declarations/meta/Option';
import {OptionInfo} from '../../../declarations/meta/OptionInfo';

export class OptionInfoBuilder extends SubModelBuilder<MetaFieldBuilder, OptionInfo> {

  constructor(parentBuilder: MetaFieldBuilder, onBuild: (buildResult: OptionInfo) => void) {
    super(parentBuilder, {options: []}, onBuild);
  }

  public withContextOptionRelationFilter(value: boolean = true): OptionInfoBuilder {
    this.product.context_object_relation_filter = value;
    return this;
  }

  public withInlineCheckField(fieldName?: string): OptionInfoBuilder {
    this.product.inline_check_field = fieldName;
    return this;
  }

  public withInlineValueField(fieldName?: string): OptionInfoBuilder {
    this.product.inline_value_field = fieldName;
    return this;
  }

  public withOption(label: Option['label'],
                    description: Option['description'],
                    relationObjectType: Option['relation_object_type'],
                    value: Option['value']): OptionBuilder {
    return new OptionBuilder(this, label, description, relationObjectType, value, opt => this.product.options.push(opt));
  }

}