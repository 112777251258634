import {SubModelBuilder} from './SubModelBuilder';
import {ReferenceBuilder} from './ReferenceBuilder';
import {ReferenceFilterValue} from '../../../declarations/meta/ReferenceFilterValue';
import {ReferenceFilter} from '../../../declarations/meta/ReferenceFilter';

export class ReferenceFilterBuilder extends SubModelBuilder<ReferenceBuilder, ReferenceFilter> {

  constructor(parentBuilder: ReferenceBuilder, onBuild: (buildResult: (ReferenceFilter)) => void) {
    super(parentBuilder, {values: []}, onBuild);
  }

  public withFilterField(fieldName: string): ReferenceFilterBuilder {
    this.product.filter_field = fieldName;
    return this;
  }

  public withFilterFieldValue(value: string | ReferenceFilterValue): ReferenceFilterBuilder {
    this.product.values?.push(value);
    return this;
  }
}