import {ModelBuilder} from './ModelBuilder';
import {FieldType, getFieldTypeFromInputType} from '../../../declarations/FieldType';
import {SubModelBuilder} from './SubModelBuilder';
import {ActionBuilder} from './ActionBuilder';
import {MetaField} from '../../../declarations/meta/MetaField';
import {DisplayBuilder} from './DisplayBuilder';
import {FieldIfBuilder} from './FieldIfBuilder';
import {OptionInfoBuilder} from './OptionInfoBuilder';
import {InlineBuilder} from './InlineBuilder';
import {ReferenceBuilder} from './ReferenceBuilder';
import {ValidationBuilder} from './ValidationBuilder';
import {InputType} from '../../../declarations/InputType';
import {Action} from '../../../declarations/meta/Action';
import {Display} from '../../../declarations/meta/Display';
import {FieldIf} from '../../../declarations/meta/FieldIf';

export class MetaFieldBuilder extends SubModelBuilder<ModelBuilder, MetaField> {

  private readonly field: MetaField;

  constructor(modelBuilder: ModelBuilder,
              inputType: InputType,
              propName: string,
              onBuild?: (buildResult: MetaField) => void) {
    super(modelBuilder,
      {
        field_id: propName,
        input_type: inputType,
        field_type: getFieldTypeFromInputType(inputType),
        name: propName,
        actions: [],
        field_ifs: []
      } as MetaField,
      onBuild
    );
    this.field = this.product;
  }

  public withId(id: string, uuid?: string): MetaFieldBuilder {
    this.field.field_id = id;
    this.field.field_uuid = uuid;
    return this;
  }

  public withFieldType(fieldType: FieldType): MetaFieldBuilder {
    this.field.field_type = fieldType;
    return this;
  }

  public withSection(section: string): MetaFieldBuilder {
    this.field.section = section;
    return this;
  }

  public withOrder(order: number): MetaFieldBuilder {
    this.field.order = order;
    return this;
  }

  public withSubOrder(subOrder: number): MetaFieldBuilder {
    this.field.sub_order = subOrder;
    return this;
  }

  public withAction(trigger: Action['trigger'] = 'on_click'): ActionBuilder {
    return new ActionBuilder(this, trigger, action => this.field.actions!.push(action));
  }

  public withTitle(fieldTitle: string, adminTitle?: string): MetaFieldBuilder {
    this.field.field_title = fieldTitle;
    this.field.admin_title = adminTitle || fieldTitle;
    return this;
  }

  public notIndexable(): MetaFieldBuilder {
    this.field.index = {indexable: 'no'};
    return this;
  }

  public required(): MetaFieldBuilder {
    this.field.required = 'required';
    return this;
  }

  public edit(edit?: MetaField['edit']): MetaFieldBuilder {
    this.field.edit = edit;
    return this;
  }

  public display(show: Display['show'] = 'yes'): DisplayBuilder {
    return new DisplayBuilder(this, show, display => this.field.display = display);
  }

  public withValidation(): ValidationBuilder {
    return new ValidationBuilder(this, validation => this.product.validation = validation);
  }

  public withFieldIf(ifType: FieldIf['if_type'], operator?: FieldIf['operator']): FieldIfBuilder<MetaFieldBuilder> {
    return new FieldIfBuilder(this, ifType, operator, fif => this.field.field_ifs?.push(fif));
  }

  public withOptionInfo(): OptionInfoBuilder {
    return new OptionInfoBuilder(this, oi => this.field.option_info = oi);
  }

  public withInline(model: string): InlineBuilder {
    return new InlineBuilder(this, model, inline => this.field.inline = inline);
  }

  public withReference(): ReferenceBuilder {
    return new ReferenceBuilder(this,ref => this.field.reference = ref);
  }

}