import React, {FC, useEffect, useState} from 'react';
import {createStyles, makeStyles, styled, Theme} from '@material-ui/core/styles';
import {CheckCircle, Close, Error, Info, Warning} from '@material-ui/icons';
import {CircularProgress, IconButton, Paper} from '@material-ui/core';

export interface AlertProps {
  severity?: 'error' | 'warning' | 'info' | 'success';
  message?: string;
  onClose?: () => void;
  closeable?: boolean;
  autoCloseAfterMs?: number;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  error: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.getContrastText(theme.palette.error.main)
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.getContrastText(theme.palette.warning.main)
  },
  info: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.getContrastText(theme.palette.info.main)
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.getContrastText(theme.palette.success.main)
  }
}));

const Container = styled(Paper)({
  display: 'flex',
  flexFlow: 'row nowrap',
  justifyContent: 'space-between',
  alignItems: 'center'
});

const Icon = styled('span')(({theme}) => ({
  margin: theme.spacing(0, 2)
}));

const Message = styled('p')({
  maxHeight: '50px',
  overflowX: 'hidden',
  overflowY: 'auto'
});

const CloseButton = styled(IconButton)({
  position: 'relative',
  color: 'inherit'
});

const Placeholder = styled('span')(({theme}) => ({
  marginRight: theme.spacing(1)
}));

const Timer = styled(CircularProgress)({
  position: 'absolute',
  right: '4px',
  opacity: 0.5
});

export const Alert: FC<AlertProps> = ({
                                        severity,
                                        message,
                                        onClose,
                                        closeable = false,
                                        autoCloseAfterMs
}) => {
  const classes = useStyles();
  const [timerProgress, setTimerProgress] = useState<number>(100);
  const [closed, setClosed] = useState<boolean>(false);
  const [firedCloseEvent, setFiredCloseEvent] = useState<boolean>(false);

  useEffect(() => {
    if (closeable && !!autoCloseAfterMs) {
      const timeout = setTimeout(() => setClosed(true), autoCloseAfterMs);

      const interval = setInterval((start: number) => {
        const progress = ((Date.now() - start) / autoCloseAfterMs) * 100;
        setTimerProgress(100 - progress);
        if (progress > 100) {
          clearInterval(interval);
        }
      }, 100, Date.now());

      return () => {
        clearTimeout(timeout);
        clearInterval(interval);
      }
    }
    return () => {};
  }, [closeable, autoCloseAfterMs]);

  useEffect(() => {
    if (closed && onClose && !firedCloseEvent) {
      onClose();
      setFiredCloseEvent(true);
    }
  }, [closed, onClose, firedCloseEvent]);

  return (
      <Container elevation={4} className={severity ? classes[severity] : classes.info}>
        <Icon>
          { severity === 'info' && <Info/>}
          { severity === 'error' && <Error/>}
          { severity === 'warning' && <Warning/>}
          { severity === 'success' && <CheckCircle/>}
        </Icon>
        <Message>{ message }</Message>
        {
          closeable
            ?
              <CloseButton type="button" onClick={() => setClosed(true)}>
                {
                  !!autoCloseAfterMs &&
                  <Timer variant="static"
                         color="inherit"
                         value={timerProgress}/>
                }
                <Close/>
              </CloseButton>
            :
              <Placeholder/>
        }
      </Container>
  );
};

export default Alert;