import React, {FC, MouseEvent, TouchEvent, useState} from 'react';
import {DeleteForever} from '@material-ui/icons';
import {ButtonBase, styled, Theme} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

export interface SwipeDeleteProps {
  position?: 'left' | 'right';
  id?: string;
  onDelete: (id?: string) => void;
}

interface StyleProps extends Pick<SwipeDeleteProps, 'position'> {
  offset?: number
}

type SwipeEvent = MouseEvent | TouchEvent;


const SNAP_THRESHOLD: number = 0.5;
const MAX_OFFSET_PX: number = 75;

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  deleteButtonIcon: {
    position: 'absolute',
    display: 'flex',
    cursor: 'pointer',
    color: theme.palette.error.contrastText,
    width: MAX_OFFSET_PX + 'px',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    right: props => props.position === 'left' ? 'unset' : 0,
    left: props => props.position === 'left' ? 0 : 'unset',
  },
  swipeArea: {
    position: 'relative',
    display: 'inline',
    left: props => `${props.offset || 0}px`,
    transition: 'left 250ms ease-in-out'
  }
})
);

const Container = styled('div')(({theme}) => ({
  position: 'relative',
  cursor: 'grab',
  backgroundColor: theme.palette.error.main,
  borderRadius: theme.shape.borderRadius
}));

/**
 * Component that adds a delete-button underneath the children that will be
 * revealed when the user swipes left or right.
 * @param {React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined} children
 * @param location
 * @return {any}
 * @constructor
 */
export const SwipeDelete: FC<SwipeDeleteProps> = ({
                                             children,
                                             id,
                                             onDelete,
                                             position = 'right'
                                           }) => {
  const [startX, setStartX] = useState<number>(0);
  const [offset, setOffset] = useState<number>(0);
  const classes = useStyles({position, offset});
  const [isPressed, setIsPressed] = useState<boolean>(false);

  const getXPosFromEvent = (event: SwipeEvent): number => {
    let clientX: number;
    if ('clientX' in event) {
      clientX = event.clientX;
    } else {
      clientX = event.changedTouches[0]?.clientX;
    }
    return clientX | 0;
  };

  const handleDragStart = (e: SwipeEvent) => {
    setIsPressed(true);
    setStartX(getXPosFromEvent(e) - offset);
  };

  const handleDrag = (e: SwipeEvent) => {
    if (!isPressed) {
      return;
    }
    const currentX = getXPosFromEvent(e);
    let newOffset = currentX - startX;

    if (position === 'right' && currentX < (startX - MAX_OFFSET_PX)) {
      // Moved past MAX on the left side
      newOffset = -MAX_OFFSET_PX;
    } else if (position === 'left' && currentX > (startX + MAX_OFFSET_PX)) {
      // Moved past MAX on the right side
      newOffset = MAX_OFFSET_PX;
    } else if ((position === 'right' && currentX > startX) ||
      (position === 'left' && currentX < startX)) {
      // Moved past initial point in the wrong direction
      newOffset = 0;
    }

    setOffset(newOffset);
  };

  const handleDragEnd = () => {
    if (isPressed) {
      setIsPressed(false);
    } else {
      return;
    }

    const endValue = (Math.abs(offset) > MAX_OFFSET_PX * SNAP_THRESHOLD) ? MAX_OFFSET_PX : 0;
    setOffset(position === 'left' ? endValue : -endValue);
  };

  return (
    <Container role="button" onClick={() => onDelete(id)}>

      <ButtonBase className={classes.deleteButtonIcon}>
        <DeleteForever/>
      </ButtonBase>

      <div className={classes.swipeArea}
           onMouseDown={handleDragStart}
           onTouchStart={handleDragStart}
           onMouseMove={handleDrag}
           onTouchMove={handleDrag}
           onMouseUp={handleDragEnd}
           onMouseOut={handleDragEnd}
           onTouchCancel={handleDragEnd}
           onTouchEnd={handleDragEnd}
           onClick={e => e.stopPropagation()}>
        {children}
      </div>
    </Container>
  );
};

export default SwipeDelete;