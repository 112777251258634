import React, {FC} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles({
  positionTop: {
    alignSelf: 'flex-start'
  },
  positionCenter: {
    alignSelf: 'center'
  },
  positionBottom: {
    alignSelf: 'flex-end'
  }
})

export interface ConfirmDialogProps {
  open: boolean;
  title?: string;
  description?: string;
  agreeButtonText?: string;
  disagreeButtonText?: string;
  onClose: (confirm: boolean) => void;
  position?: 'top' | 'center' | 'bottom';
}

export const ConfirmDialog: FC<ConfirmDialogProps> = ({
                                                 open,
                                                 title,
                                                 description,
                                                 agreeButtonText,
                                                 disagreeButtonText,
                                                 onClose,
                                                 position= 'center'
}) => {

  const classes = useStyles();
  let positionClass = classes.positionCenter;

  if (position !== 'center') {
    positionClass = position === 'top' ? classes.positionTop : classes.positionBottom;
  }

  return (
    <Dialog open={open}
            classes={{paper: positionClass}}
            aria-labelledby={title ? 'confirm-dialog-title' : undefined}
            aria-describedby={description ? 'confirm-dialog-description' : undefined}>
      {
        title &&
        <DialogTitle id="confirm-dialog-title">{title}</DialogTitle>
      }
      {
        description &&
        <DialogContent id="confirm-dialog-description">
          <DialogContentText>
            {description}
          </DialogContentText>
        </DialogContent>
      }
      <DialogActions>
        <Button type="button" onClick={() => onClose(false)}>
          {disagreeButtonText}
        </Button>
        <Button type="button" variant="contained" color="primary" onClick={() => onClose(true)}>
          {agreeButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;