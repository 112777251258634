export type BarcodeChangeHandler = (event: string) => void;

/**
 * This is a service for scanning barcodes using a device that emulates a keyboard.
 */
export class BarcodeScannerService {

  private readonly bypassIds: Array<string>;
  private readonly delimiter: string
  private onIsListeningHandler: (isListening: boolean) => void;
  private isScanningChangedHandler: (isScanning: boolean) => void;
  private barcodeChangedHandler: BarcodeChangeHandler;
  private barcodeCharReadHandler: BarcodeChangeHandler;
  private currentCode: string;

  constructor(bypassIds: Array<string> = [], delimiter: string = 'Enter') {
    this.bypassIds = bypassIds;
    this.delimiter = delimiter;
    this.onIsListeningHandler = () => {};
    this.isScanningChangedHandler = () => {};
    this.barcodeChangedHandler = () => {console.warn(`[${BarcodeScannerService.name}] No callback registered. Please provide a callback to handle barcodes.`)};
    this.barcodeCharReadHandler = () => {};
    this.currentCode = '';
    this.startListening = this.startListening.bind(this);
    this.stopListening = this.stopListening.bind(this);
    this.onEachRead = this.onEachRead.bind(this);
    this.onBarcodeRead = this.onBarcodeRead.bind(this);
    this.onIsListening = this.onIsListening.bind(this);
    this.bypassEvent = this.bypassEvent.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.preventDefault = this.preventDefault.bind(this);
    this.onIsScanning = this.onIsScanning.bind(this);
  }

  /**
   * Start listening for keyboard-events
   */
  public startListening(): void {
    document.addEventListener('keydown', this.onKeyDown);
    document.addEventListener('keypress', this.preventDefault);
    document.addEventListener('keyup', this.preventDefault);
    this.onIsListeningHandler(true);
  }

  /**
   * Stop listening for keyboard-events
   */
  public stopListening(): void {
    document.removeEventListener('keydown', this.onKeyDown);
    document.removeEventListener('keypress', this.preventDefault);
    document.removeEventListener('keyup', this.preventDefault);
    this.onIsListeningHandler(false);
  }

  /**
   * Fired for each character read by the scanner-service
   * @param {BarcodeChangeHandler} handler
   */
  public onEachRead(handler: BarcodeChangeHandler): void {
    this.barcodeCharReadHandler = handler || (() => {});
  }

  /**
   * Fired only when the barcode has been read copletely
   * @param {BarcodeChangeHandler} handler
   */
  public onBarcodeRead(handler: BarcodeChangeHandler): void {
    this.barcodeChangedHandler = handler || (() => {});
  }

  /**
   * Fired when the service starts or stops scanning a barcode
   * @param {(isScanning: boolean) => void} handler
   */
  public onIsScanning(handler: (isScanning: boolean) => void): void {
    this.isScanningChangedHandler = handler  || (() => {});
  }

  /**
   * Fired when the service starts or stops listening for key-events
   * @param {BarcodeScannerService["onIsListeningHandler"]} handler
   */
  public onIsListening(handler: BarcodeScannerService['onIsListeningHandler']): void {
    this.onIsListeningHandler = handler || (() => {});
  }

  private onKeyDown(event: KeyboardEvent): void {
    if (this.bypassEvent(event)) {
      return;
    }

    if (event.key === this.delimiter) {
      this.barcodeChangedHandler(this.currentCode);
      this.currentCode = '';
      this.isScanningChangedHandler(false);
      return;
    }

    if (event.key.length === 1) {
      this.preventDefault(event);
      if (!this.currentCode) {
        this.isScanningChangedHandler(true);
      }

      let character = event.key;

      if (event.ctrlKey && character === 'j') {
        character = String.fromCharCode(0);
      }

      this.barcodeCharReadHandler(character);
      this.currentCode += character;
    }
  }

  private bypassEvent(event: KeyboardEvent): boolean {
    if (!this.bypassIds?.length || !event?.target) {
      return false;
    }
    const id = (event.target as Element)?.id;
    return !!id && this.bypassIds.includes(id);
  }

  private preventDefault(event: KeyboardEvent): void {
    if (!this.bypassEvent(event)) {
      event.preventDefault();
      event.stopPropagation();
    }
  }
}