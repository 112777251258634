import React, {Context, createContext, FC, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {Snackbar, SnackbarProps} from '@material-ui/core';
import Alert, {AlertProps} from '../components/Alert';


type Anchor = SnackbarProps['anchorOrigin'];
type Severity = AlertProps['severity'];

export interface SystemMessageServiceProps extends Omit<SnackbarProps, 'children'> {
  position?: 'topLeft' | 'topCenter' | 'topRight' | 'bottomLeft' | 'bottomCenter' | 'bottomRight';
  transformMessage?: (message: string) => string;
}

interface Message {
  message: string,
  severity?: Severity;
  duration?: number;
}


export interface SystemMessageServiceContextValue {
  broadcastInfo: (message: string) => void;
  broadcastSuccess: (message: string) => void;
  broadcastWarning: (message: string) => void;
  broadcastError: (message: string) => void;
}

const CONTEXT_VALUE: SystemMessageServiceContextValue = {
  broadcastInfo: console.log,
  broadcastSuccess: console.log,
  broadcastWarning: console.warn,
  broadcastError: console.error,
};

const SystemMessageServiceContext: Context<SystemMessageServiceContextValue> = createContext(CONTEXT_VALUE);

export const useSystemMessageService = () => useContext(SystemMessageServiceContext);









export const SystemMessageService: FC<SystemMessageServiceProps> = ({
                                                                      children,
                                                                      position = 'bottomCenter',
                                                                      transformMessage = (m => m)
}) => {
  const anchorOrigin = useMemo<Anchor>(() => {
    const positionObject: Anchor = {vertical: 'bottom', horizontal: 'center'};
    switch (position) {
      case 'topLeft':
      case 'topCenter':
      case 'topRight':
        positionObject.vertical = 'top'
    }
    switch (position) {
      case 'topLeft':
      case 'bottomLeft':
        positionObject.horizontal = 'left';
        break;
      case 'topRight':
      case 'bottomRight':
        positionObject.horizontal = 'right';
    }
    return positionObject;
  }, [position]);
  const [messages, setMessages] = useState<Array<Message>>([]);
  const [currentMessage, setCurrentMessage] = useState<Message | null>(null);

  const broadcast = useCallback((message: string, severity: Severity, duration: number = 10000) => {
    setMessages(msgs => [...msgs, {message: transformMessage(message), severity, duration}]);
  }, [transformMessage]);

  useEffect(() => {
    CONTEXT_VALUE.broadcastInfo = msg => broadcast(msg, 'info', 7000);
    CONTEXT_VALUE.broadcastSuccess = msg => broadcast(msg, 'success', 5000);
    CONTEXT_VALUE.broadcastWarning = msg => broadcast(msg, 'warning', 8000);
    CONTEXT_VALUE.broadcastError = msg => broadcast(msg, 'error', 10000);
  }, [broadcast]);


  const handleClose = () => {
    setCurrentMessage(null);
  };

  useEffect(() => {
    if (currentMessage == null && !!messages?.length) {
      const next = messages[0];
      setMessages(msgs => msgs.slice(1));
      setCurrentMessage(next);
    }
  }, [messages, currentMessage]);

  return (
    <SystemMessageServiceContext.Provider value={CONTEXT_VALUE}>
      {children}

      <Snackbar open={currentMessage !== null} key={currentMessage?.message}
                anchorOrigin={anchorOrigin}>
        <Alert severity={currentMessage?.severity}
               message={currentMessage?.message}
               autoCloseAfterMs={currentMessage?.duration}
               closeable={true}
               onClose={handleClose}/>
      </Snackbar>

    </SystemMessageServiceContext.Provider>
  );
};

export default SystemMessageService;