import React, {FC, useEffect, useState} from 'react';
import {usePrimusApi} from '../../hooks/PrimusApiProvider';
import PrimusEkulturLoading from './PrimusEkulturLoading';
import {CALLBACK_URI} from './PrimusEkulturLoginGlobals';
import {useLoginLabels} from './PrimusEkulturLabelProvider';

export const PrimusEkulturLoginCallback: FC = () => {
  const api = usePrimusApi()!;
  const labels = useLoginLabels();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    const params = window.location.search.slice(1);
    const paramsDict: any = params.split('&')
      .map(param => param.split('='))
      .reduce((dict, [paramName, paramVal]) => ({
        ...dict,
        [paramName]: decodeURI(paramVal)
      }), {});
    const code = paramsDict['code'];
    const state = paramsDict['state'];

    if (code && state) {
      api.requestTokenFromCode(code, state, CALLBACK_URI)
        .then(loginSuccess => window.opener.postMessage({loginSuccess}))
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, [api]);


  return (
    <PrimusEkulturLoading loading={loading} text={labels.controllingCredentials}/>
  );
};

export default PrimusEkulturLoginCallback;