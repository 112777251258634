import {SubModelBuilder} from './SubModelBuilder';
import {MetaFieldBuilder} from './MetaFieldBuilder';
import {DisplayLabel} from '../../../declarations/meta/DisplayLabel';
import {Display} from '../../../declarations/meta/Display';

export class DisplayBuilder extends SubModelBuilder<MetaFieldBuilder, Display> {

  constructor(parentBuilder: MetaFieldBuilder, show: Display['show'], onBuild: (buildResult: Display) => void) {
    super(parentBuilder, {show, label: {}}, onBuild);
  }

  public withCheckbox(checkbox: boolean = true): DisplayBuilder {
    this.product.label!.checkbox = checkbox;
    return this;
  }

  public withCheckboxField(checkboxField: string): DisplayBuilder {
    this.product.label!.checkbox_field = checkboxField;
    return this;
  }

  public withCssEditClass(className: DisplayLabel['css_class_edit']): DisplayBuilder {
    this.product.label!.css_class_edit = className;
    return this;
  }

  public withHeadline(headline: boolean = true): DisplayBuilder {
    this.product.label!.headline = headline;
    return this;
  }

  public hidden(hidden: boolean = true): DisplayBuilder {
    this.product.label!.hide = hidden;
    return this;
  }

  public withIcon(icon: string): DisplayBuilder {
    this.product.label!.icon = icon;
    return this;
  }

  public withNewline(newLine: boolean = true): DisplayBuilder {
    this.product.label!.new_line = newLine;
    return this;
  }

  public withOrder(order: number): DisplayBuilder {
    this.product.label!.order = order;
    return this;
  }

  public withPrefix(prefix: string): DisplayBuilder {
    this.product.label!.prefix = prefix;
    return this;
  }

}