import React, {FC, useMemo} from 'react';
import NO from '../asset/png/flags/NO.png';
import SV from '../asset/png/flags/SV.png';
import EN from '../asset/png/flags/EN.png';
import {IconButton} from '@material-ui/core';

export type Language = 'EN' | 'NO' | 'SV';

export interface FlagButtonProps {
  language?: Language;
  onClick?: () => void;
}

export const FlagButton: FC<FlagButtonProps> = ({language, onClick}) => {

  const flag = useMemo(() => {
    switch (language) {
      case 'NO':
        return NO;
      case 'SV':
        return SV;
      default:
        return EN;
    }
  }, [language]);

  return (
    <IconButton name={language} onClick={() => onClick && onClick()}>
      <img src={flag} alt={language + ' flag-image'}/>
    </IconButton>
  );
};

export default FlagButton;