import {SubModelBuilder} from './SubModelBuilder';
import {InlineBuilder} from './InlineBuilder';
import {IndexNotification} from '../../../declarations/meta/IndexNotification';
import {InlineList} from '../../../declarations/meta/InlineList';

export class InlineListBuilder extends SubModelBuilder<InlineBuilder, InlineList> {


  constructor(parentBuilder: InlineBuilder, onBuild: (buildResult: InlineList) => void) {
    super(parentBuilder, {index_notifications: []}, onBuild);
  }

  public fixed(value: boolean = true): InlineListBuilder {
    this.product.fixed = value;
    return this;
  }

  public withMaxLength(maxLength: number): InlineListBuilder {
    this.product.max_length = maxLength;
    return this;
  }

  public withIndexNotification(index: number, message: string, messageClass: IndexNotification['message_class']): InlineListBuilder {
    this.product.index_notifications!.push({
      index,
      message,
      message_class: messageClass
    });
    return this;
  }

}