import React, {cloneElement, FC} from 'react';
import {ArrayFieldTemplateProps} from '@rjsf/core';
import {Button, ButtonGroup, createStyles, Paper, Theme, Typography} from '@material-ui/core';
import {Add, ArrowDownward, ArrowUpward, Remove} from '@material-ui/icons';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },
    itemButtonGroup: {
      marginLeft: theme.spacing(1)
    },
    addButton: {
      alignSelf: 'flex-start'
    },
    itemContainer: {
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'flex-start',
      alignItems: 'center',
      width: '100%',
      margin: theme.spacing(2, 0)
    },
    fieldContainer: {
      width: '100%',
      padding: theme.spacing(1, 2),
    },
    warning: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText
    },
    divider: {
      height: '1px',
      width: '100%',
      backgroundColor: theme.palette.divider,
    }
  })
);

type Item = ArrayFieldTemplateProps['items'][0];

export const ArrayFieldTemplate: FC<ArrayFieldTemplateProps> = ({
                                                           uiSchema,
                                                           className,
                                                           title,
                                                           items,
                                                           canAdd,
                                                           onAddClick,
                                                           disabled
}) => {
    const classes = useStyles();

    const moveUp = (item: Item) => item.onReorderClick(item.index, item.index - 1);
    const moveDown = (item: Item) => item.onReorderClick(item.index, item.index + 1);

    const toItem = (item: Item) => {
      return (
        <div key={item.key} className={classes.itemContainer}>
          <Paper variant="outlined" className={classes.fieldContainer}>
            {cloneElement(item.children, {uiSchema: uiSchema['items']})}
          </Paper>

          <ButtonGroup className={classes.itemButtonGroup}
                       disabled={disabled}
                       variant="outlined"
                       color="primary"
                       disableElevation
                       orientation="vertical">
            <Button disabled={!item.hasMoveUp}
                    onClick={moveUp(item)}>
              <ArrowUpward/>
            </Button>
            <Button disabled={!item.hasMoveDown}
                    onClick={moveDown(item)}>
              <ArrowDownward/>
            </Button>
            <Button disabled={!item.hasRemove}
                    className={classes.warning}
                    onClick={item.onDropIndexClick(item.index)}>
              <Remove/>
            </Button>
          </ButtonGroup>
        </div>
      )
    };

    return (
        <div className={`${className} ${classes.root}`}>
          <Typography variant="h6" component="span">{title}</Typography>

          {items && items.map(toItem)}

          {
            canAdd &&
            <Button className={classes.addButton}
                    disabled={disabled}
                    type="button"
                    variant="outlined"
                    color="secondary"
                    onClick={onAddClick}>
              <Add/> {title}
            </Button>
          }
        </div>
    );
};

export default ArrayFieldTemplate;