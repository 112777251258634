import "whatwg-fetch";

export abstract class HttpService {

  protected async get(path: string, config?: Request): Promise<Response> {
    return this.http(path, 'GET', config);
  }

  protected async put(path: string, body: any, config?: Request, stringifyBody: boolean = true): Promise<Response> {
    return this.http(path, 'PUT',{...config, body: stringifyBody ? JSON.stringify(body) : body} as Request);
  }

  protected async post(path: string, body: any, config?: Request, stringifyBody: boolean = true): Promise<Response> {
    return this.http(path, 'POST',{...config, body: stringifyBody ? JSON.stringify(body) : body} as Request);
  }

  protected async delete(path: string, config?: Request): Promise<Response> {
    return this.http(path, 'DELETE', config);
  }

  private combineDeep<T>(obj1: Partial<T>, obj2: Partial<T>): Partial<T> {
    if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
      return obj2 || obj1;
    }
    return Object.keys(obj2).reduce((obj, key) => {
      let value = obj2[key];
      if (value !== undefined) {

        if (typeof value === 'object') {
          if (value instanceof Headers) {
            if (!!obj1[key]) {
              value = obj1[key];
              (obj2[key] as Headers).forEach((header, name) => {
                if (value.has(name)) {
                  value.delete(name);
                }
                if (name && header) {
                  value.append(name, header);
                }
              })
            }
          } else {
            value = this.combineDeep(obj[key], value);
          }
        }

        obj[key] = value;
      }
      return obj;
    }, obj1);
  }

  protected combineConfigs(...configs: Array<Partial<Request>>): Request {
    return configs.reduce((res, cfg) => this.combineDeep(res, cfg), {} as Request) as Request;
  }

  protected getDefaultConfig(): Request {
    return this.combineConfigs({
      headers: new Headers({
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      })
    });
  }

  protected getPathPrefix(): string {
    return '';
  }

  protected abstract getBaseUrl(): string;

  private async http(path: string, method: 'GET' | 'PUT' | 'POST' | 'DELETE', config?: Request): Promise<Response> {
    try {

      if (!this.getBaseUrl()) {
        console.warn('[HttpService] BaseURL not set');
      }

      const cfg: Request = this.combineConfigs(
        this.getDefaultConfig(),
        config || {},
        {method}
      );

      const url: string = (cfg.url || `${this.getBaseUrl()}${this.getPathPrefix()}`);

      const res: Response = await fetch(`${url}${path}`, cfg);
      if (res.ok) {
        return res;
      } else {
        // noinspection ExceptionCaughtLocallyJS
        throw res;
      }
    } catch (e) {
      console.error("[HttpService] Request failed: ", e);
      throw e;
    }
  }

}