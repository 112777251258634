import {ModelBuilder} from './ModelBuilder/ModelBuilder';
import {Model} from '../../declarations/Model';

/**
 * A builder, utilizing ModelBuilder, to generate complete and realistic models to simplify testing
 */
export class ModelTestDataBuilder {
  private readonly modelBuilder: ModelBuilder;

  constructor(modelName: string) {
    this.modelBuilder = new ModelBuilder(modelName);
  }

  public createIdentifierField(propName: string = 'identification_number', label: string = 'TRANS__FIELD__IDENTIFICATION_NUMBER', value?: string): ModelTestDataBuilder {
    this.modelBuilder
      .createIdentifierField(propName, label, value)
        .withValidation()
          .withMin(2)
          .withMax(50)
          .complete()
      .required()
        .complete();
    return this;
  }

  public createRefArrayField(propName: string = 'designations', label: string = 'TRANS__FIELD__DESIGNATIONS', value: Array<string> = []): ModelTestDataBuilder {
    this.modelBuilder
      .createRefArrayField(propName, label, 'ct_22', value)
        .withReferenceFilter()
        .withFilterField('filters.superobject_type_id')
        .withFilterFieldValue('ct_128-d796ee93-0106-4b80-92c5-6f2913b559d5')
        .withFilterFieldValue('ct_128-d796ee93-0109-4b80-92c5-6f2913b559d5')
        .complete()
      .canAddNew()
        .withNewParams('TRANS__FIELD__DESIGNATIONS__ADD_NEW_TITLE', 'ct_22')
        .withNameFieldPath('name.name')
        .withFullObjectEdit()
        .withCopyField({
          "copy_source_type": "parent_object",
          "copy_source_field": "superobject_type_id",
          "copy_target_field": "filters[0].superobject_type_id"
        })
        .complete()
      .searchKulturnav()
        .withKulturnavParams('Concept')
        .withConceptTypeId('ct_22')
        .complete()
      .complete()
        .withInline('Designation')
        .withProp('designation_type_id')
        .addObjectName()
        .complete()
      .complete();
    return this;
  }

  public createObjectReferenceFieldWithInline(propName: string = 'history_events', label: string = 'TRANS__HISTORY__EVENTS', value: Array<any> = []): ModelTestDataBuilder {
    this.modelBuilder
      .createObjectReferenceField(propName, label, value)
        .withObjectType('HistoryEvent')
        .withSort('order_number desc')
        .withReferenceProp('history_event_id')
        .canAddNew()
          .withNewParams('TRANS__FIELD__HISTORY_EVENT__ADD' ,'HistoryEvent')
            .withFullObjectEdit()
          .complete()
        .withSearchDisplayLimit(1)
        .complete()
      .withInline('SuperobjectHistoryEventItem')
        .complete()
      .complete();
    return this;
  }

  public createObjectReferenceField(propName: string = 'publish_event', label: string = 'TRANS__FIELD__PUBLISH', value: Array<any> = []): ModelTestDataBuilder {
    this.modelBuilder
      .createObjectReferenceField(propName, label, value)
        .withObjectType('PublishingEvent')
        .withSort('created_at desc')
        .withParentField('artifact_id')
        .withParentTargetField('context.context_artifact_id')
        .canAddNew()
          .withNewParams('TRANS__FIELD__PUBLISH__SET' ,'PublishingEvent')
            .withFullObjectEdit()
              .withCopyField({
                copy_source_type: "prev_object",
                copy_source_field: "publish",
                copy_target_field: "publish",
                copy_default_value: false,
                copy_transform_type: "!"
              })
              .withCopyField({
                copy_source_type: "parent_object",
                copy_source_field: "artifact_id",
                copy_target_field: "context.context_artifact_id"
              })
          .complete()
        .withSearchDisplayLimit(1)
        .complete()
      .complete();
    return this;
  }

  public createInlineArrayField(propName: string = 'spatial_references', label: string = 'TRANS__FIELD__SPATIAL_REFERENCES', value: Array<object> = []): ModelTestDataBuilder {
    this.modelBuilder
      .createInlineArrayField(propName, label, 'SpatialReferenceItem', value)
        .withView()
        .complete()
      .complete();
    return this;
  }

  public createInlineField(propName: string = 'purpose', label: string = 'TRANS__FIELD__PURPOSE', value?: object): ModelTestDataBuilder {
    this.modelBuilder
      .createInlineField(propName, label, 'PurposePlacement', 'purpose_description', value)
        .complete()
      .complete();
    return this;
  }

  public createSearchSelectorMultipleField(propName: string = 'contexts', label: string = 'TRANS__FIELD__TASK_CONTEXTS', value: Array<string> = []): ModelTestDataBuilder {
    this.modelBuilder
      .createSearchSelectorMultipleField(propName, label, 'TaskContextItem', 'context_artifact_id', value)
        .withMetaType('artifact')
          .withSelector('select:multiple', 'selector_relations_artifacts_and_adm_events', 'TRANS__SELECTOR__SELECT_OBJECT', true)
          .complete()
        .complete()
      .complete();
    return this;
  }

  public createSearchSelectorField(propName: string = 'relation_id', label: string = 'TRANS__FIELD__OBJECT_RELATIONSHIP', value?: string): ModelTestDataBuilder {
    this.modelBuilder
      .createSearchSelectorField(propName, label, value)
        .withMetaType('artifact')
        .withSelector('select:single', 'selector_relations', 'TRANS__SELECTOR__SELECT_RELATION', true)
          .withCheckedFilter('object_type', 'object_type')
          .complete()
        .complete()
      .required()
      .complete();
    return this;
  }

  public createMapIdField(propName: string = 'registration_level_id', label: string = 'TRANS__FIELD__REG_LEVEL', value?: string): ModelTestDataBuilder {
    this.modelBuilder
      .createMapIdField(propName, label, 'ct_0', value)
      .withSort('name')
        .withReferenceFilter()
        .withFilterField('filters.superobject_type_id')
        .withFilterFieldValue("ct_128-d796ee93-0106-4b80-92c5-6f2913b559d5")
        .complete()
      .complete().required()
      .complete();
    return this;
  }

  public createCheckArrayField(propName: string = 'event_names',
                               label: string = 'TRANS__REPORT_MENU__BELONGING_EVENTS__TITLE',
                               value: Array<any> = []): ModelTestDataBuilder {
    this.modelBuilder
      .createCheckArrayField(propName, label, 'ReportAdminEvent', value)
      .withInlineValueField('name')
      .withInlineCheckField('selected')
      .withContextOptionRelationFilter()
      .withOption('TRANS__REPORT_MENU__BELONGING_EVENTS__TITLE', 'TRANS__REPORT_MENU__BELONGING_EVENTS__DESCRIPTION', '', 'setAll')
      .withIsAllToggle()
      .complete()
      .withOption('TRANS__REPORT_MENU__DAMAGES_DEVIATIONS__TITLE', 'TRANS__REPORT_MENU__DAMAGES_DEVIATIONS__DESCRIPTION', 'ObservationEvent', 'damages')
      .complete()
      .withOption('TRANS__REPORT_MENU__TREATMENTS__TITLE', 'TRANS__REPORT_MENU__TREATMENTS__DESCRIPTION', 'ObservationEvent', 'treatment_events')
      .selected()
      .complete()
      .withOption('TRANS__REPORT_MENU__BUILDING_TREATMENTS__TITLE', 'TRANS__REPORT_MENU__BUILDING_TREATMENTS__DESCRIPTION', 'BuildingTreatmentEvent', 'building_treatment_events')
      .complete()
      .complete()
      .complete()
    return this;
  }

  public createStringField(propName: string = 'string', label: string = 'Text string', value?: string): ModelTestDataBuilder {
    this.modelBuilder
      .createStringField(propName, label, value)
      .required()
      .withValidation()
        .withMax(10)
        .withMin(0)
        .complete()
      .complete()
    return this;
  }

  public createNumberField(propName: string = 'number', label: string = 'Number', value?: number): ModelTestDataBuilder {
    this.modelBuilder
      .createNumberField(propName, label, value)
        .withValidation()
        .withMax(10)
        .withMin(-10)
        .complete()
        .complete();
    return this;
  }

  public createTextAreaField(propName: string = 'textArea', label: string = 'Text area'): ModelTestDataBuilder {
    this.modelBuilder
      .createTextAreaField(propName, label, 'Some text in a text area')
      .withValidation()
      .withMax(3000)
      .withMin(10)
      .complete()
      .required()
      .complete();
    return this;
  }

  public createToAndFromDateFields(propNameFrom: string = 'fromDate',
                                   propNameTo: string = 'toDate',
                                   labelFrom: string = 'From date',
                                   labelTo: string = 'To date',
                                   valueFrom?: number,
                                   valueTo?: number): ModelTestDataBuilder {
    this.modelBuilder
      .createDateField(propNameFrom,  labelFrom, valueFrom)
      .todayDate()
      .withToDateField(propNameTo)
      .complete()
      .withValidation()
      .withCompareField(propNameTo, '<=')
      .complete()
      .complete()

      .createDateField(propNameTo, labelTo, valueTo)
      .isToDateField()
      .checkExpired()
      .complete()
      .withValidation()
      .withCompareField(propNameFrom, '>=')
      .complete()
      .complete();
    return this;
  }

  public createCheckboxField(propName: string = 'checkbox', label: string = 'Check me!', value: boolean = false): ModelTestDataBuilder {
    this.modelBuilder
      .createCheckboxField(propName, label, value)
      .complete();
    return this;
  }

  public build(): Model {
    return this.modelBuilder.build();
  }

  public getBuilder(): ModelBuilder {
    return this.modelBuilder;
  }
}