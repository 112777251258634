import {MetaFieldBuilder} from './MetaFieldBuilder';
import {BaseBuilder} from './BaseBuilder';
import {ActionBuilder} from './ActionBuilder';
import {InlineBuilder} from './InlineBuilder';
import {OptionInfoBuilder} from './OptionInfoBuilder';
import {ReferenceBuilder} from './ReferenceBuilder';
import {Model} from '../../../declarations/Model';
import {MetaField} from '../../../declarations/meta/MetaField';
import {Meta} from '../../../declarations/Meta';
import {InputType} from '../../../declarations/InputType';
import {PrecisionDate} from '../../../declarations/PrecisionDate';

/**
 * A utility used to more easily create models (primarily) for testing.
 */
export class ModelBuilder extends BaseBuilder<Model> {

  private readonly fields: Array<MetaField>;
  private readonly model: Model;
  private readonly meta: Meta;

  constructor(modelName: string) {
    super({
      model_title: modelName,
      object_type: modelName,
      checksum: 0,
      artifact_name_upper: modelName.toUpperCase()
    } as Model);
    this.fields = [];
    this.meta = {
      sections: []
    } as any as Meta;
    this.model = this.product;

  }

  public createSection(name: string, title?: string, order?: number): ModelBuilder {
    this.meta.sections!.push({
      name,
      title: title || name,
      order: (!!order && order >= 0) ? order : this.meta.sections!.length,
      fields: []
    });
    return this;
  }

  public createIdentifierField(propName: string, label: string, value?: string): MetaFieldBuilder {
    return this.createField(InputType.IDENTIFIER, propName, label, value);
  }

  public createRefArrayField(propName: string, label: string, objectType: string, value: Array<any> = []): ReferenceBuilder {
    return this.createField(InputType.REF_ARRAY, propName, label, value)
      .withReference()
        .withObjectType(objectType);
  }

  public createObjectReferenceField(propName: string, label: string, value: Array<any> = []): ReferenceBuilder {
    return this.createField(InputType.OBJECT_REFERENCE, propName, label, value)
      .withReference();
  }

  public createSearchSelectorMultipleField(propName: string, label: string, inlineModel?: string, inlineProp?: string, value?: Array<string>): ReferenceBuilder {
    return this.createField(InputType.SEARCH_SELECTOR_MULTIPLE, propName, label, value)
      .withInline(inlineModel || '')
        .withProp(inlineProp || '')
      .complete()
      .withReference();
  }

  public createSearchSelectorField(propName: string, label: string, value?: string): ReferenceBuilder {
    return this.createField(InputType.SEARCH_SELECTOR, propName, label, value)
      .withReference();
  }

  public createMapIdField(propName: string, label: string, objectType: string, value?: string): ReferenceBuilder {
    return this.createField(InputType.MAP_ID, propName, label, value)
      .withReference()
      .withObjectType(objectType)
  }

  public createCheckArrayField(propName: string, label: string, model: string, value?: Array<any>): OptionInfoBuilder {
    return this.createField(InputType.CHECK_ARRAY, propName, label, value)
      .withInline(model)
        .complete()
      .withOptionInfo();
  }

  public createCheckboxField(propName: string, label: string, value: boolean = false): MetaFieldBuilder {
    return this.createField(InputType.CHECKBOX, propName, label, value);
  }

  public createStringField(propName: string, label: string, value?: string): MetaFieldBuilder {
    return this.createField(InputType.INPUT, propName, label, value);
  }

  public createNumberField(propName: string, label: string, value?: number): MetaFieldBuilder {
    return this.createField(InputType.NUMBER, propName, label, value);
  }

  public createInlineField(propName: string, label: string, modelName: string, modelProp?: string, value?: object): InlineBuilder {
    return this.createField(InputType.INLINE, propName, label, value)
      .withInline(modelName)
      .withProp(modelProp!);
  }

  public createInlineArrayField(propName: string, label: string, modelName: string, value: Array<object>): InlineBuilder {
    return this.createField(InputType.INLINE_ARRAY, propName, label, value)
      .withInline(modelName);
  }

  public createActionButton(propName: string, label: string, value?: string): ActionBuilder {
    return this.createField(InputType.ACTION_BUTTON, propName, label, value).withAction('on_click');
  }

  public createTextAreaField(propName: string, label: string, value?: string): MetaFieldBuilder {
    return this.createField(InputType.TEXT_AREA, propName, label, value);
  }

  public createDateField(propName: string, label: string, value?: number, precision: PrecisionDate['dd_precision'] = 'datetime'): InlineBuilder {
    let startValue: PrecisionDate | null = null;
    if (!!value && value >= 0) {
      startValue = {
        dd_precision: precision,
        dd_date: value
      } as PrecisionDate;
    }
    return this.createField(InputType.PRECISION_DATE, propName, label, startValue)
      .withInline('PrecisionDate');
  }

  private createField(inputType: InputType, propName: string, label: string, value?: any): MetaFieldBuilder {
    this.model[propName] = value ?? null;
    return new MetaFieldBuilder(this, inputType, propName, field => {
        this.meta[field.name] = field;
        this.fields.push(field);
      })
      .display().complete()
      .edit('edit')
      .withTitle(label, label)
      .withOrder(this.fields.length);
  }

  /**
   * Completes the model
   * @return {Model}
   */
  public build(): Model {
    this.meta.sections!.forEach(s => s.fields = this.fields.filter(f => f.section === s.name));
    this.model.$$meta = this.meta;
    return super.build();
  }
}